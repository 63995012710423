import React, { useCallback } from 'react'
import Header from './Header'
import Footer from './Footer'
import useWindowSize from '../../lib/windowSize'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'

function SubPageLayout({ children }: { children: React.ReactNode }) {
  const { width } = useWindowSize()
  const { pathname } = useLocation()

  const handleBackLine = useCallback(() => {
    if (pathname.includes('members')) return
    return width <= 767 ? (
      <>
        <BackLine style={{ left: '8vw' }} />
        <BackLine style={{ left: '50%', right: '50%' }} />
        <BackLine style={{ right: '8vw' }} />
      </>
    ) : (
      <>
        <BackLine style={{ left: '13vw' }} />
        <BackLine style={{ left: '31.25vw' }} />
        <BackLine style={{ left: '50%', right: '50%' }} />
        <BackLine style={{ right: '31.25vw' }} />
        <BackLine style={{ right: '13vw' }} />
      </>
    )
  }, [pathname, width])

  return (
    <>
      <Header />
      {handleBackLine()}
      {children}
      {!pathname.includes('contacts') && <Separator />}
      {!pathname.includes('contacts') && <Footer />}
    </>
  )
}

export default SubPageLayout

const BackLine = styled.div`
  @media screen and (min-width: 768px) {
    top: 0px;
    background: #ededed;
    width: 1px;
    height: 100%;
    z-index: -1;
    position: fixed;
  }

  @media screen and (max-width: 767px) {
    top: 0px;
    background: #ededed;
    width: 1px;
    height: 100%;
    z-index: -99;
    position: fixed;
  }
`

const Separator = styled.div`
  @media screen and (min-width: 768px) {
    height: 2px;
    background-color: #ededed;
    width: 100%;
  }

  @media screen and (max-width: 767px) {
  }
`
