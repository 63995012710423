import { useState } from 'react'
import styled from 'styled-components'
import WorksNav from '../../components/pages/works/WorksNav'
import SubPageLayout from '../../components/layouts/SubPageLayout'
import Works from '../../components/pages/works/Works'
import { Outlet, useParams } from 'react-router-dom'

const Index = () => {
  const params = useParams()
  const [curNavIndex, setCurNavIndex] = useState<number>(0)

  return (
    <SubPageLayout>
      <Main>
        <WorksNav setNavIndex={setCurNavIndex} curNavIndex={curNavIndex} />
        {params.id ? <Outlet /> : <Works />}
      </Main>
    </SubPageLayout>
  )
}

export default Index

const Main = styled.div`
  @media screen and (min-width: 768px) {
    display: flex;
    flex-direction: row;
    margin-top: 269px;
    margin-bottom: 144px;
    margin-left: 13vw;
    margin-right: 13vw;
  }

  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
    margin-top: 142px;
    margin-left: 8vw;
    margin-right: 8vw;
  }
`
