import React from 'react'
import { ThemeProvider } from 'styled-components'
import './i18n'
import reportWebVitals from './reportWebVitals'
import Routes from './routes'
import { LanguageProvider } from './context/languageContext'
import theme from './styles/theme'
import GlobalStyle from './styles/globalStyle'
import ChannelService from './lib/channelService'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { createRoot } from 'react-dom/client'

const App = () => <Routes />

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 600000,
      cacheTime: 600000,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
    },
  },
})

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <ThemeProvider theme={theme}>
        <LanguageProvider>
          <GlobalStyle />
          <App />
        </LanguageProvider>
      </ThemeProvider>
    </QueryClientProvider>
  </React.StrictMode>,
)

reportWebVitals()
ChannelService.boot({
  pluginKey: process.env.REACT_APP_CHANNEL_TALK_KEY,
})

ChannelService.boot({
  pluginKey: process.env.REACT_APP_CHANNEL_TALK_KEY,
  profile: {
    name: 'YOUR_USER_NAME',
    mobileNumber: 'YOUR_USER_MOBILE_NUMBER',
    CUSTOM_VALUE_1: 'VALUE_1',
    CUSTOM_VALUE_2: 'VALUE_2',
  },
})
