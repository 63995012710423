import styled from 'styled-components'
import noticeDefaultImg from '../../../../assets/images/noticeDefaultImg.png'
import { customizeDate } from '../../../../lib/customizeDate'
import { Link } from 'react-router-dom'

interface IProps {
  notice: INotice
}

function Notice({ notice }: IProps) {
  const { title, titleImage, createdAt, hashtags, id } = notice

  return (
    <NoticeContainer>
      <div className="noticeSection">
        <Link to={`/community/notice/${id}`}>
          <div className="title">{title}</div>
        </Link>
        <div>
          <div className="hashTag">{hashtags.map((item: HashtagType) => item.name)}</div>
          <div className="postedDate">{customizeDate(createdAt)}</div>
        </div>
      </div>

      <div className="noticeImg">
        <Link to={`/community/notice/${id}`}>
          <img alt="noticeImg" src={titleImage ? titleImage : noticeDefaultImg} />
        </Link>
      </div>
    </NoticeContainer>
  )
}

export default Notice

const NoticeContainer = styled.div`
  @media screen and (min-width: 768px) {
    width: 100%;
    height: 157.95px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    img {
      height: 100%;
      width: 157.95px;
    }

    .noticeSection {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    }

    .title {
      height: 80px;
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 40px;
      color: #414141;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      margin-right: 10px;
    }

    .hashTag {
      height: 24px;
      font-family: 'pretandard-light';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      color: #414141;
    }

    .postedDate {
      height: 19px;
      font-family: 'Optima';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #a8a8a8;
      margin-top: 10px;
    }
  }

  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 157.95px;
    img {
      height: 100%;
      max-width: 157.95px;
    }

    margin-bottom: 20.05px;
    .noticeSection {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    }

    .title {
      height: 80px;
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 40px;
      color: #414141;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .hashTag {
      height: 24px;
      font-family: 'Optima';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      color: #414141;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }

    .postedDate {
      margin-top: 10px;
      height: 19px;
      font-family: 'Optima';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #a8a8a8;
    }
  }
`
