import React from 'react'
import removeIcon from '../assets/icon/removeIcon.png'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { InfiniteData } from '@tanstack/react-query'

interface HashtagStateProps<T> {
  path: string
  data?: InfiniteData<{ data: T; nextPage: any; total: number }>
}

const HashtagState = <T,>({ data, path }: HashtagStateProps<T>) => {
  const navigate = useNavigate()
  const searchParamsHashtagId = new URLSearchParams(location.search).get('hashtagId')

  const Hashtag: { name: string; id: number }[] = []

  data?.pages
    .flatMap((page) => page.data)
    .map((item) => {
      const tmp = (item as { hashtags: { id: number; name: string }[] }).hashtags.filter((item: { id: number }) => {
        return item.id === Number(searchParamsHashtagId)
      })

      return Hashtag.push(tmp[0])
    })

  const result = Hashtag.filter((item) => {
    return item !== undefined
  })

  return (
    <Wrapper>
      {searchParamsHashtagId && (
        <div className="curHashtag">
          <span className="tagName">{result[0]?.name} </span>
          <span role="button">
            <img
              onClick={() => {
                navigate(path)
              }}
              id="removeBtn"
              src={removeIcon}
              alt="removeBtn"
            />
          </span>
        </div>
      )}
    </Wrapper>
  )
}

export default HashtagState

const Wrapper = styled.div`
  #removeBtn {
    margin-left: 9px;
    margin-right: 13px;
    position: relative;
    top: 2.9px;
    cursor: pointer;
  }

  .tagName {
    margin-left: 14px;
  }

  .curHashtag {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    text-transform: capitalize;
    color: #004099;
    height: 44px;
    width: fit-content;
    border-radius: 40px;
    box-sizing: border-box;
    border: 1px solid #004099;
    margin-bottom: 25px;
  }

  @media screen and (max-width: 767px) {
    .curHashtag {
      position: absolute;
      top: 216px;
    }

    .mobile {
      margin-bottom: 30px;
    }
  }
`
