import React, { useState } from 'react'
import styled from 'styled-components'
import { postContacts } from '../../../models/main'
import PopupModal from '../../PopupModal'
import { useLocation } from 'react-router-dom'
import useWindowSize from '../../../lib/windowSize'
import FooterTwitter from '../../../assets/icon/FooterTwitter.svg'
import FooterInsta from '../../../assets/icon/FooterInsta.svg'
import emailBlue from '../../../assets/icon/newMail.svg'
import { checkContactsForm } from '../../../lib/checkContactsForm'

function Index() {
  const [name, setName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [message, setMessage] = useState<string>('')
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const { pathname } = useLocation()
  const { width } = useWindowSize()

  const onChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value)
  }
  const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
  }
  const onChangeMessage = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value)
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const isCheckedForm = checkContactsForm(name, email, message)

    if (isCheckedForm) {
      postContacts(name, email, message)
      setName('')
      setEmail('')
      setMessage('')
      setModalOpen(true)
    }
  }

  const closeModal = (): void => {
    setModalOpen(false)
  }

  return (
    <IndexArea>
      <Container path={pathname}>
        <form onSubmit={handleSubmit}>
          <div id="contacts">Contacts</div>
          <div className="sendDiv">
            <div id="litter">
              개발 견적 문의, 협력 제안, 입사 문의 등 궁금한게 있으시다면 언제든 연락주세요. <br />
              빠르게 검토한 후 회신드리겠습니다
            </div>
            <div className="btn_Box">
              <button className="button" type="submit">
                Send
              </button>
            </div>
          </div>

          {width <= 767 && (
            <div className="icons">
              <ul>
                <li>
                  <a target="_blank" href=" https://twitter.com/we_PLANET">
                    <img id="twitterIcon" src={FooterTwitter} alt="twitterIcon" />
                  </a>
                </li>
                <li>
                  <a target="_blank" href="mailto:weplanet@wepla.net" rel="noopener noreferrer">
                    <img id="beIcon" src={emailBlue} alt="beIcon" />
                  </a>
                </li>
                <li>
                  <a target="_blank" href=" https://www.instagram.com/weplanet/ ">
                    <img id="instagramIcon" src={FooterInsta} alt="instagramIcon" />
                  </a>
                </li>
              </ul>
            </div>
          )}

          <div className="form_Box">
            <div>
              <div className="info">NAME</div>
              <div>
                <input className="form" value={name} onChange={onChangeName} placeholder={'이름을 입력해주세요.'} />
              </div>
            </div>
            <div>
              <div className="info">E-MAIL</div>
              <div>
                <input
                  className="form"
                  value={email}
                  onChange={onChangeEmail}
                  placeholder={'연락 가능한 회신 이메일을 등록해주세요.'}
                />
              </div>
            </div>
            <div>
              <div className="info">MESSAGE</div>
              <div>
                <textarea
                  className="textAreaForm"
                  value={message}
                  onChange={onChangeMessage}
                  placeholder={'내용을 입력해주세요. (최대 500자)'}
                />
              </div>
            </div>
          </div>
          <div className="copyrightDiv">
            <div id="copyright">Copyright 2022 Weplanet Co., Ltd. all rights reserved.</div>
            <div className="aboutDoc">
              <div id="about"> About</div>
              <div id="doc">Documents</div>
            </div>
          </div>
        </form>
        <PopupModal open={modalOpen} close={closeModal} pathname={pathname} />
      </Container>
    </IndexArea>
  )
}

export default Index

const Container = styled.div<{ path: string }>`
  @media screen and (min-width: 768px) {
    margin-top: 144px;
    height: auto;
    margin-bottom: ${(props) => (props.path === '/' ? 340 : 21)}px;
    margin-left: 13vw;
    margin-right: 13vw;
    button {
      cursor: pointer;
    }

    input {
      font-size: 16px;
      padding-left: 12px;
      width: 99%;
    }

    input::placeholder {
      height: 30px;
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 30px;
      color: #cfcfcf;
    }

    input:focus {
      outline: none;
    }

    textArea {
      padding-left: 12px;
      padding-top: 20px;
      width: 99%;
    }

    textArea::placeholder {
      height: 30px;
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #cfcfcf;
    }

    textArea:focus {
      outline: none;
    }

    .sendDiv,
    .copyrightDiv,
    .aboutDoc {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    #contacts {
      font-family: 'Optima';
      font-style: normal;
      font-weight: 400;
      font-size: 28px;
      line-height: 30px;
      color: #000000;
      margin-bottom: 16px;
    }

    #litter {
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 30px;
      color: #414141;
    }

    .button {
      width: 10vw;
      height: 3.3vw;
      background-color: transparent !important;
      background-image: none !important;
      border: 1px solid #004099;
      box-sizing: border-box;
      border-radius: 16px;
      font-family: 'Optima';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 30px;
      color: #004099;
    }

    .sendDiv {
      margin-bottom: 20px;
    }

    .info {
      font-family: 'Optima';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 3px;
      text-transform: uppercase;
      color: #000000;
      margin-bottom: 28px;
    }

    .form {
      background: #fafafa;
      mix-blend-mode: normal;
      opacity: 0.9;
      border-radius: 4px 4px 0px 0px;
      margin-bottom: 44px;
      height: 54px;
    }

    .textAreaForm {
      background: #fafafa;
      mix-blend-mode: normal;
      opacity: 0.9;
      border-radius: 4px 4px 0px 0px;
      margin-bottom: 44px;
      height: 120px;
    }

    .copyrightDiv {
      margin-top: 12vh;
      height: 30px;
    }

    #copyright {
      white-space: nowrap;
      height: 30px;
      font-family: 'Nunito Sans-semibold';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 30px;
      color: #979797;
    }

    #about {
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 16px;
      line-height: 30px;
      text-align: right;
      color: #979797;
      margin-right: 40px;
    }

    #doc {
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 16px;
      line-height: 30px;
      text-align: right;
      color: #979797;
    }
  }

  @media screen and (max-width: 767px) {
    /* width: 100%; */
    margin-bottom: 60px;
    margin-left: 8vw;
    margin-right: 8vw;
    margin-top: 30px;

    .form_Box {
      margin-bottom: 142px;
    }

    .icons ul {
      display: flex;
      flex-direction: row;
      width: 114px;
      justify-content: space-between;
      height: 16px;
      margin-bottom: 60px;
    }

    .info {
      height: 16px;
      font-family: 'Nunito';
      font-style: normal;
      font-weight: 800;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 3px;
      text-transform: uppercase;
      color: #000000;
      /* margin-bottom: 15px; */
    }

    .form {
      mix-blend-mode: normal;
      opacity: 0.9;
      border-radius: 4px 4px 0px 0px;
      margin-bottom: 44px;
      height: 54px;
    }

    .textAreaForm {
      mix-blend-mode: normal;
      opacity: 0.9;
      border-radius: 4px 4px 0px 0px;
      /* margin-bottom: 44px; */
      height: 120px;

      margin-top: 15px;
    }

    #contacts {
      height: 26px;
      font-family: 'Nunito';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 26px;
      color: #000000;
      margin-bottom: 10px;
    }

    #litter {
      font-family: 'Nunito';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 30px;
      color: #000000;
      margin-bottom: 21px;
    }

    .btn_Box {
      position: relative;
    }

    .button {
      width: 166px;
      height: 56px;
      background-color: transparent !important;
      background-image: none !important;
      border: 1px solid #004099;
      box-sizing: border-box;
      border-radius: 16px;
      font-family: 'Optima';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 30px;
      color: #004099;
      position: absolute;
      top: 483px;
      right: 0;
    }

    button {
      cursor: pointer;
    }

    input {
      font-size: 16px;
      width: 100%;
    }

    input::placeholder {
      height: 30px;
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 30px;
      color: #cfcfcf;
    }

    input:focus {
      outline: none;
    }

    textArea {
      width: 100%;
    }

    textArea::placeholder {
      height: 30px;
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 30px;
      color: #cfcfcf;
    }

    textArea:focus {
      outline: none;
    }

    .copyrightDiv {
      height: auto;
    }

    #copyright {
      height: 30px;
      font-family: 'Nunito Sans-semibold';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 30px;
      color: #000000;
    }

    .aboutDoc {
      height: 30px;
      font-family: 'Nunito';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 30px;
      color: #979797;
    }
  }
`

const IndexArea = styled.div`
  @media screen and (max-width: 767px) {
    width: 100%;
    border-top: 1px solid #ededed;
    z-index: 999;
    background: white;
  }
`
