import React, { createContext, PropsWithChildren, useCallback, useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { useCookies } from 'react-cookie'

interface ILanguageContextProps {
  onChangeLanguage: (lang?: string) => void
  language?: string
}

const defaultValue: ILanguageContextProps = {
  onChangeLanguage: () => undefined,
  language: undefined,
}

const LanguageContext = createContext(defaultValue)

export const LanguageProvider = ({ children }: PropsWithChildren<any>) => {
  const { i18n } = useTranslation()
  const [cookies, setCookie] = useCookies(['i18next'])

  const handleChangeLanguage = useCallback(
    (language?: string) => {
      if (language) {
        i18n.changeLanguage(language)
        setCookie('i18next', language)
      }
    },
    [i18n],
  )

  useEffect(() => {
    let lang = cookies.i18next

    if (!lang)
      switch (navigator.language) {
        case 'en':
        case 'en-US':
          lang = 'en'
          break
        default:
          lang = 'ko'
          break
      }

    setCookie('i18next', lang)
  }, [cookies])

  return (
    <LanguageContext.Provider value={{ onChangeLanguage: handleChangeLanguage, language: cookies.i18next }}>
      {children}
    </LanguageContext.Provider>
  )
}

export default LanguageContext
