function checkContactsForm(name: string, email: string, message: string): boolean {
  const pattern = /\s/g
  /* eslint-disable */
  const reg_email = /^([0-9a-zA-Z_\.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/

  if (name.length < 1) {
    alert('이름을 입력해주세요.')
    return false
  } else if (email.length < 1) {
    alert('E-Mail을 입력해주세요.')
    return false
  } else if (message.length < 1) {
    alert('내용을 입력해주세요.')
    return false
  } else if (name.length > 30 || name.match(pattern)) {
    alert('형식에 맞는 NAME을 입력해주세요.')
    return false
  } else if (!reg_email.test(email)) {
    alert('형식에 맞는 E-MAIL을 입력해주세요.')
    return false
  } else if (message.length > 500) {
    alert('MESSAGE는 500자를 넘을 수 없습니다.')
    return false
  }

  return true
}

export { checkContactsForm }
