import { useInfiniteQuery } from '@tanstack/react-query'
import { membersQueryKey } from '../constants/query-key'
import { getMembers } from './main'

export const useInfiniteMembers = (params: Params) => {
  return useInfiniteQuery({
    queryKey: [...membersQueryKey.membersInfinite, params],
    queryFn: async ({ pageParam = 0 }) => {
      const { data } = await getMembers({
        ...params,
        start: pageParam,
      })

      return {
        data: data.data,
        nextPage: pageParam,
        total: data.total,
      }
    },
    getNextPageParam: (lastPage) => {
      if (lastPage.nextPage >= lastPage.total) {
        return false
      } else {
        return lastPage.nextPage + 10
      }
    },
  })
}
