import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import { getRecruitById, getRecruits } from './main'
import { recruitsQueryKey } from '../constants/query-key'

export const useRecruits = (params: Params, loaded: boolean) => {
  return useQuery([...recruitsQueryKey.recruits, params], () => getRecruits(params), {
    enabled: loaded,
  })
}

export const useRecruitsId = (id: number) => {
  return useQuery(recruitsQueryKey.recruitsId(id), () => getRecruitById(id))
}

export const useInfiniteRecruits = (params: Params) => {
  return useInfiniteQuery({
    queryKey: [...recruitsQueryKey.recruitsInfinite, params],
    queryFn: async ({ pageParam = 0 }) => {
      const { data } = await getRecruits({
        ...params,
        start: pageParam,
      })

      return {
        data: data.data,
        nextPage: pageParam,
        total: data.total,
      }
    },
    getNextPageParam: (lastPage) => {
      if (lastPage.nextPage >= lastPage.total) {
        return false
      } else {
        return lastPage.nextPage + 10
      }
    },
  })
}
