import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Pretendard-Medium', 'Apple SD Gothic Neo', Sans-serif;
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
  }

  * {
    margin: 0;
    padding: 0;
    text-decoration: none;
    color: inherit; /* 링크의 색상 제거 */

  }

  body {
    height: 100%;
    width: 100%;
    font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
    /* .middleSeparator{
      margin-top: 143px;
    } */

    #loading {
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 3px;
      text-transform: uppercase;
      color: #004099;
    }

    background: #FFFFFF;


    input, textarea {
      width: 100%;
      border: none;
      border-bottom: 1px solid #CFCFCF;
    }


  }


  ul {
    list-style: none;
  }

  html {
    width: 100%;
  }
`
export default GlobalStyle
