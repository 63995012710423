import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useCallback } from 'react'
import { Container } from '../../NavContainer'

const TAB = [
  { category: 'blog', name: 'BLOG' },
  { category: 'recruit', name: 'RECRUIT' },
  { category: 'notice', name: 'NOTICE' },
]

function CommunityNav() {
  const params = useParams()
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const handleNavClicked = (category: string): void => {
    navigate(`/community/${category}`)
  }

  const handleNavTab = useCallback(() => {
    return TAB.map((item, index) => (
      <div
        key={item.name}
        style={params.category === item.category ? { color: '#004099' } : { color: '#CFCFCF' }}
        className={`li_${index}`}
        onClick={() => {
          handleNavClicked(item.category)
        }}
      >
        {item.name}
      </div>
    ))
  }, [params])

  return <Container path={pathname}>{handleNavTab()}</Container>
}

export default CommunityNav
