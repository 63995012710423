import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import { noticeQueryKey } from '../constants/query-key'
import { getNoticeById, getNotices } from './main'

export const useNotice = (params: Params, loaded: boolean) => {
  return useQuery([...noticeQueryKey.notice, params], () => getNotices(params), {
    enabled: loaded,
  })
}

export const useNoticeId = (id: number) => {
  return useQuery(noticeQueryKey.noticeId(id), () => getNoticeById(id))
}

export const useInfiniteNotice = (params: Params) => {
  return useInfiniteQuery({
    queryKey: [...noticeQueryKey.noticeInfinite, params],
    queryFn: async ({ pageParam = 0 }) => {
      const { data } = await getNotices({
        ...params,
        start: pageParam,
      })

      return {
        data: data.data,
        nextPage: pageParam,
        total: data.total,
      }
    },
    getNextPageParam: (lastPage) => {
      if (lastPage.nextPage >= lastPage.total) {
        return false
      } else {
        return lastPage.nextPage + 10
      }
    },
  })
}
