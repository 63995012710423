import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Link, useLocation } from 'react-router-dom'
import link from '../../../../assets/icon/link.svg'
import email from '../../../../assets/icon/email.svg'
import { customizeDate } from '../../../../lib/customizeDate'
import { CopyToClipboard } from 'react-copy-to-clipboard'

interface IProps {
  recruit: IRecruit
  setPosition?: any
}

function RecruitByCommunity({ recruit }: IProps) {
  const { pathname } = useLocation()
  const { title, content, endDate, position, positionHexColor, id } = recruit
  const [date, setDate] = useState<string>()
  const [value, setValue] = useState<string>('')
  const [ToastStatus, setToastStatus] = useState(false)

  useEffect(() => {
    if (endDate !== null) {
      const date = customizeDate(endDate)
      const finalDate = date + ' 마감'
      setDate(finalDate)
    } else {
      const date = '상시채용'
      setDate(date)
    }
  }, [])
  useEffect(() => {
    setValue(`https://www.wepla.net/community/recruit/${recruit.id}`)
  }, [])

  useEffect(() => {
    if (ToastStatus) {
      setTimeout(() => setToastStatus(false), 1000)
    }
  }, [ToastStatus])

  const handleToast = () => {
    setToastStatus(true)
    window.scrollTo({ top: 0, left: 0, behavior: 'auto' })
  }

  const Toast = () => {
    return <div className="toast">게시글 링크가 복사되었습니다.</div>
  }

  return (
    <Card pathname={pathname} endDate={endDate} positionColor={positionHexColor}>
      {ToastStatus && <Toast />}
      <div className="endDate">{date}</div>
      <Link to={`/community/recruit/${id}`}>
        <div className="title_box">
          <div className="title">{title}</div>
          <span className="more">더보기</span>
        </div>
      </Link>
      <div className="position">{position}</div>
      <div className="content" dangerouslySetInnerHTML={{ __html: content }} />
      <div className="etc">
        <CopyToClipboard text={value}>
          <div className="link" onClick={handleToast}>
            <img id="link" src={link} alt="link" />
          </div>
        </CopyToClipboard>

        <a href="mailto:weplanet@wepla.net">
          <div className="email">
            <div>
              <img id="email" src={email} alt="email" />
            </div>
            <span className="email_text">e-mail로 지원하기</span>{' '}
          </div>
        </a>
      </div>
    </Card>
  )
}

export default RecruitByCommunity

const Card = styled.div<{ pathname: string; endDate: string; positionColor: string }>`
  @media screen and (min-width: 768px) {
    width: 100%;
    height: 265px;
    position: relative;
    background: #ffffff;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-bottom: 32px;

    .endDate {
      top: 20px;
      right: 20px;
      position: absolute;
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 27px;
      text-align: right;
      color: ${(props) => (props.endDate !== null ? '#FF3131' : '#007fff')};
      mix-blend-mode: normal;
      opacity: 0.8;
    }

    .title {
      cursor: pointer;
      height: 30px;
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 30px;
      text-decoration-line: underline;
      text-transform: capitalize;
      color: #414141;
      text-underline-position: under;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      margin-top: 33px;
    }

    .title_box {
      margin-left: 32px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .more {
      margin-top: 33px;
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 30px;
      text-decoration-line: underline;
      text-transform: capitalize;
      color: #979797;
      margin-left: 8px;
      white-space: nowrap;
      cursor: pointer;
    }

    .position {
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: ${(props) => props.positionColor};
      mix-blend-mode: normal;
      opacity: 0.8;
      margin-top: 14px;
      margin-left: 32px;
    }

    .content {
      width: 535px;
      margin-left: 32px;
      height: 78px;
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 26px;
      text-transform: capitalize;
      color: #a8a8a8;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      margin-top: 14px;
      margin-bottom: 25px;
    }

    .etc {
      margin-left: 32px;
      margin-bottom: 24px;
      display: flex;
      flex-direction: row;
    }

    .link {
      background: #ffffff;
      box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
      border-radius: 6px;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .email {
      display: flex;
      flex-direction: row;
      height: 29.55px;
      background: #ffffff;
      box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
      border-radius: 6px;
      justify-content: center;
      align-items: center;
      margin-left: 14px;
      cursor: pointer;
    }

    .email_text {
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
      text-transform: capitalize;
      color: #4d4d4d;
      margin-right: 7.5px;
    }

    #email {
      margin-left: 9.75px;
      margin-right: 11.65px;
      position: relative;
      top: 2px;
    }

    .toast {
      position: absolute;
      top: 50%;
      left: 50%;
      padding: 11px;
      min-width: 200px;
      transform: translate(-50%, -50%);
      z-index: 3;
      background: rgba(0, 0, 0, 0.7);
      color: #fff;
      border-radius: 4px;
      border: 1px solid #000;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
    }
  }

  @media screen and (max-width: 1145px) {
    .endDate {
      display: none;
    }
  }

  @media screen and (max-width: 767px) {
    padding: 1px;
    width: 100%;
    height: 387px;
    background: #ffffff;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-bottom: 30px;

    .endDate {
      display: block;
      color: ${(props) => (props.endDate !== null ? '#FF3131' : '#007fff')};
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 27px;
      mix-blend-mode: normal;
      opacity: 0.8;
      margin-left: 20px;
      margin-top: 20px;
    }

    .title {
      cursor: pointer;
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 30px;
      text-decoration-line: underline;
      text-transform: capitalize;
      color: #414141;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-underline-position: under;
    }

    .title_box {
      margin-left: 20px;
      margin-right: 20px;
      margin-top: 8px;
    }

    .more {
      margin-top: 7px;
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 30px;
      text-decoration-line: underline;
      text-transform: capitalize;
      color: #979797;
      text-underline-position: under;
    }

    .position {
      color: ${(props) => props.positionColor};
      margin-left: 20px;
      margin-top: 21px;
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 1px;
      text-transform: uppercase;
      mix-blend-mode: normal;
      opacity: 0.8;
      margin-bottom: 14px;
    }

    .content {
      height: 104px;
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 26px;
      text-transform: capitalize;
      color: #a8a8a8;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      margin-top: 14px;
      margin-bottom: 25px;
      margin-left: 20px;
      margin-right: 20px;
    }

    .etc {
      margin-left: 20px;
      margin-bottom: 24px;
      display: flex;
      flex-direction: row;
    }

    .link {
      background: #ffffff;
      box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
      border-radius: 6px;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .email {
      display: flex;
      flex-direction: row;
      height: 29.55px;
      background: #ffffff;
      box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
      border-radius: 6px;
      justify-content: center;
      align-items: center;
      margin-left: 14px;
      cursor: pointer;
    }

    .email_text {
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
      text-transform: capitalize;
      color: #4d4d4d;
      margin-right: 7.5px;
    }

    #email {
      margin-left: 9.75px;
      margin-right: 11.65px;

      position: relative;
      top: 2px;
    }

    .toast {
      position: absolute;
      top: 50%;
      left: 50%;
      padding: 11px;
      min-width: 200px;
      transform: translate(-50%, -50%);
      z-index: 3;
      background: rgba(0, 0, 0, 0.7);
      color: #fff;
      border-radius: 4px;
      border: 1px solid #000;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
    }
  }
`
