import styled from 'styled-components'
import useWindowSize from '../lib/windowSize'

function PopupModal({ open, close, pathname }: { open: boolean; close: () => void; pathname?: string }) {
  const { width } = useWindowSize()

  return (
    <Container path={pathname}>
      {open ? (
        <div className="modal__background">
          <div className="modal__box">
            <div className="title">문의 안내</div>
            <div className="line" />
            <div className="content">
              {width <= 767 ? (
                <>
                  메시지가 성공적으로
                  <br />
                  등록되었습니다.
                  <br />
                  담당자 확인 후 빠르게
                  <br />
                  회신드리겠습니다.
                </>
              ) : (
                <>
                  메시지가 성공적으로 등록되었습니다.
                  <br /> 담당자 확인 후 빠르게 회신드리겠습니다.
                </>
              )}
            </div>
            <div
              className="ok_button"
              onClick={() => {
                close()
              }}
            >
              확인
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </Container>
  )
}

export default PopupModal

const Container = styled.div<{ path: any }>`
  @media screen and (min-width: 768px) {
    .modal__background {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.3);
      z-index: 9999;
      display: flex;
      justify-content: center;
      height: 3235px;
      height: ${(props) => (props.path === '/' ? 8000 : 3235)}px;
    }

    .modal__box {
      position: fixed;
      top: 20%;
      background-color: white;
      display: flex;
      width: 917px;
      height: 480px;
      background: #ffffff;
      border-radius: 14px;
      align-items: center;
      flex-direction: column;
    }

    .title {
      margin-top: 58px;
      margin-bottom: 46px;
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 28px;
      line-height: 30px;
      color: #414141;
    }

    .line {
      height: 2px;
      background: #ededed;
      width: 100%;
      margin-bottom: 32px;
    }

    .content {
      height: 196px;
      margin-bottom: 20px;
      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 40px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #414141;
    }

    .ok_button {
      cursor: pointer;
      width: 160px;
      height: 56px;
      color: white;
      background-color: #004099;
      border-radius: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  @media screen and (max-width: 767px) {
    .modal__background {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.3);
      z-index: 9999;
      display: flex;
      justify-content: center;
      height: 4335px;
    }

    .modal__box {
      /* position: relative;
            top: 11113px; */
      position: fixed;
      top: 20%;
      background-color: white;
      display: flex;
      width: 315px;
      height: 480px;
      background: #ffffff;
      border-radius: 14px;
      align-items: center;
      flex-direction: column;
    }

    .title {
      margin-top: 58px;
      margin-bottom: 46px;
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 28px;
      line-height: 30px;
      color: #414141;
    }

    .line {
      height: 2px;
      background: #ededed;
      width: 100%;
      margin-bottom: 32px;
    }

    .content {
      margin-bottom: 20px;
      width: 299px;
      height: 196px;
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 40px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #414141;
      justify-content: center;
    }

    .ok_button {
      cursor: pointer;
      width: 160px;
      height: 56px;
      color: white;
      background-color: #004099;
      border-radius: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`
