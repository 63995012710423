import { useEffect, useState } from 'react'
import styled from 'styled-components'
import useWindowSize from '../../../../lib/windowSize'
import RecruitByCommunity from './RecruitByCommunity'
import { useNavigate } from 'react-router-dom'
import useInfiniteScroll from '../../../../hooks/useInfiniteScroll'
import { useInfiniteRecruits } from '../../../../models/useRecruits'

const RecruitSection = () => {
  const { width } = useWindowSize()
  const navigate = useNavigate()
  const searchParamsHashtagId = new URLSearchParams(location.search).get('hashtagId')

  const {
    data: recruitsData,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteRecruits({
    start: 0,
    perPage: 10,
    hashtagId: searchParamsHashtagId ? Number(searchParamsHashtagId) : undefined,
  })
  const setTarget = useInfiniteScroll({ hasNextPage, isFetchingNextPage, fetchNextPage })

  const [curPosition, setCurPosition] = useState<string>('ALL')

  useEffect(() => {
    if (searchParamsHashtagId !== undefined) {
      if ((searchParamsHashtagId && searchParamsHashtagId.indexOf('ALL') > 0) || searchParamsHashtagId === 'ALL')
        setCurPosition('ALL')
      if (
        (searchParamsHashtagId && searchParamsHashtagId.indexOf('DEVELOPER') > 0) ||
        searchParamsHashtagId === 'DEVELOPER'
      )
        setCurPosition('DEVELOPER')
      if (
        (searchParamsHashtagId && searchParamsHashtagId.indexOf('PRODUCT') > 0) ||
        searchParamsHashtagId === 'PRODUCT MANAGER'
      )
        setCurPosition('PRODUCT MANAGER')
      if (
        (searchParamsHashtagId && searchParamsHashtagId.indexOf('DESIGNER') > 0) ||
        searchParamsHashtagId === 'DESIGNER'
      )
        setCurPosition('DESIGNER')
      if (
        (searchParamsHashtagId && searchParamsHashtagId.indexOf('OPERATOR') > 0) ||
        searchParamsHashtagId === 'OPERATOR'
      )
        setCurPosition('OPERATOR')
    }
  }, [])

  const filterArr: string[] = ['ALL', 'PRODUCT MANAGER', 'DEVELOPER', 'DESIGNER', 'OPERATOR']

  if (recruitsData?.pages.flatMap((page) => page.data).length === 0) {
    return null
  }

  return (
    <Container>
      <div className="position_filter">
        {filterArr.map((item, index) => (
          <div
            className="position"
            key={index}
            onClick={() => {
              setCurPosition(item)
              navigate(`/community/recruit?hashtagId=${item}`)
            }}
            style={item === curPosition ? { color: '#004099' } : { color: '#CFCFCF' }}
          >
            #{item}
          </div>
        ))}
      </div>

      <Recruits>
        <div className={width <= 767 ? 'mobile' : 'desktop'}>
          {recruitsData?.pages
            .flatMap((page) => page.data)
            .map((item: IRecruit, index: number) => (
              <div key={index}>
                <RecruitByCommunity recruit={item} setPosition={setCurPosition} />
              </div>
            ))}
        </div>
        <div ref={setTarget} />
      </Recruits>
    </Container>
  )
}

export default RecruitSection

const Container = styled.div`
  @media screen and (min-width: 768px) {
    width: 100%;
    .position_filter {
      margin-bottom: 40px;
      display: flex;
      flex-direction: row;
    }

    .position {
      cursor: pointer;
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 0.75vw;
      line-height: 14px;
      letter-spacing: 1.6px;
      text-transform: uppercase;
      margin-right: 15px;
    }
  }

  @media screen and (max-width: 767px) {
    .position_filter {
      margin-top: 80px;
      margin-bottom: 30px;
    }

    .position {
      cursor: pointer;
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 1.8px;
      text-transform: uppercase;
      display: inline-block;
    }

    .position_filter .position:first-child {
      margin-right: 20px;
    }

    .position_filter .position:nth-child(2) {
      margin-right: 20px;
    }

    .position_filter .position:nth-child(4) {
      margin-right: 20px;
    }
  }
`

const Recruits = styled.div`
  @media screen and (min-width: 768px) {
    .desktop {
      margin-bottom: 112px;
    }
  }

  @media screen and (max-width: 767px) {
    .mobile {
      margin-bottom: 61px;
    }
  }
`
