import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import twitterIcon from '../../../assets/icon/twitterIcon.svg'
import instagramIcon from '../../../assets/icon/instagramIcon.svg'
import emailIcon from '../../../assets/icon/emailIcon.svg'

function MainText() {
  const { pathname } = useLocation()
  return (
    <Container path={pathname}>
      <div id="maintext">
        <div id="Hello">Hello, We&#39;re</div>
        <div id="weplanet">Weplanet</div>
      </div>
      <div id="icons">
        <ul>
          <li>
            <a target="_blank" href=" https://twitter.com/we_PLANET">
              <img id="twitterIcon" src={twitterIcon} alt="twitterIcon" />
            </a>
          </li>
          <li>
            <a target="_blank" href=" https://www.instagram.com/weplanet/ ">
              <img id="instagramIcon" src={instagramIcon} alt="instagramIcon" />
            </a>
          </li>
          <li>
            <a target="_blank" href="mailto:weplanet@wepla.net" rel="noopener noreferrer">
              <img id="emailIcon" src={emailIcon} alt="emailIcon" />
            </a>
          </li>
        </ul>
      </div>
    </Container>
  )
}

export default MainText

const Container = styled.div<{ path: string }>`
  margin-top: 272px;
  display: flex;
  width: 100%;
  height: 275px;
  display: ${(props) => (props.path === '/' ? 'block' : 'none')};

  #maintext {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 120px;
    left: 525px;
    font-style: normal;
    font-weight: 800;
    font-size: 48px;
    line-height: 60px;
    text-align: center;

    #weplanet {
      color: ${(props) => props.theme.colors.primary};
      padding: 10px;
    }
  }

  #icons {
    height: 150px;

    ul {
      list-style-type: none;
    }

    li {
      float: left;
      padding: 15px;
    }

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  @media screen and (max-width: 767px) {
    height: 135px;

    margin-top: 162px;
    #maintext {
      height: 80px;
      width: 100%;
      text-align: center;
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 36px;
      line-height: 40px;
      text-align: center;
      color: #000000;
    }

    #icons {
      height: fit-content;
      margin-top: 23px;
    }
  }
`
