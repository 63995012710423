import buttons from '../common/buttons'

export default {
  403: {
    message: '해당 페이지 접근 권한이 없습니다.',
    button: buttons.back,
  },
  404: {
    message: '페이지를 찾을 수 없습니다.',
    button: buttons.backHome,
  },
  500: {
    message: '서버와 통신할 수 없습니다.',
    button: buttons.refresh,
  },
}
