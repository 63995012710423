import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { customizeDate } from '../../../../lib/customizeDate'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import link from '../../../../assets/icon/link.svg'
import email from '../../../../assets/icon/email.svg'
import { useRecruitsId } from '../../../../models/useRecruits'

const SearchRecruit = () => {
  const navigate = useNavigate()
  const params = useParams()
  const url = useLocation()

  const { data: recruit } = useRecruitsId(params.id as unknown as number)

  const [endDate, setEndDate] = useState<string>('')
  const [ToastStatus, setToastStatus] = useState(false)

  useEffect(() => {
    if (ToastStatus) {
      setTimeout(() => setToastStatus(false), 1000)
    }
  }, [ToastStatus])

  const handleToast = () => {
    setToastStatus(true)
  }

  const Toast = () => {
    return <div className="toast">게시글 링크가 저장되었습니다.</div>
  }

  useEffect(() => {
    if (recruit?.data.endDate) {
      const date = customizeDate(recruit?.data.endDate)
      const finalDate = date + ' 마감'
      setEndDate(finalDate)
    } else {
      const date = '상시채용'
      setEndDate(date)
    }
  }, [recruit?.data.endDate])

  if (!recruit?.data) {
    return <h1 id="loading">loading...</h1>
  }

  return (
    <Container positionColor={recruit?.data.positionHexColor} endDate={endDate}>
      {ToastStatus && <Toast />}
      <div className="top">
        <div className="title">{recruit?.data.title}</div>
        <div className="date">{customizeDate(recruit?.data.createdAt)}</div>
      </div>

      <div className="info">
        <div
          className="hashtag"
          onClick={() => {
            navigate(`/community/recruit?hashtagId=${recruit?.data.position}`)
          }}
        >
          {recruit?.data.position}
        </div>

        <div className="endDate" style={endDate === '상시채용' ? { color: '#007fff' } : { color: '#FF3131' }}>
          {endDate}
        </div>
      </div>

      <Separator />
      <div className="content" dangerouslySetInnerHTML={{ __html: recruit?.data.content }} />
      <div className="etc">
        <CopyToClipboard text={`http://dev.wepla.net${url.pathname}`}>
          <div className="link" onClick={handleToast}>
            <img id="link" src={link} alt="link" />
          </div>
        </CopyToClipboard>

        <a href="mailto:weplanet@wepla.net">
          <div className="email">
            <div>
              <img id="email" src={email} alt="email" />
            </div>
            <span className="email_text">e-mail로 지원하기</span>
          </div>
        </a>
      </div>

      <Link to="/community/recruit">
        <div className="to_list">목록보기</div>
      </Link>
    </Container>
  )
}

export default SearchRecruit

const Container = styled.div<{ positionColor: string; endDate: string }>`
  @media screen and (min-width: 768px) {
    width: 100%;
    min-height: 800px;

    .top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 25px;
    }

    .title {
      height: auto;
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 40px;
      color: #414141;
    }

    .info {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .hashtag {
      cursor: pointer;
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 1px;
      text-transform: uppercase;
      mix-blend-mode: normal;
      opacity: 0.8;
      color: ${(props) => props.positionColor};
    }

    .endDate {
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 27px;
      color: ${(props) => (props.endDate !== null ? '#FF3131' : '#007fff')};
      mix-blend-mode: normal;
      opacity: 0.8;
    }

    .date {
      height: 24px;
      font-family: 'Optima';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      color: #414141;
    }

    .content {
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 32px;
      color: #414141;
      width: 100%;
    }

    .to_list {
      margin-top: 80px;
      height: 40px;
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 40px;
      text-decoration-line: underline;
      color: #414141;
      text-underline-position: under;
    }

    .etc {
      display: flex;
      flex-direction: row;

      margin-top: 80px;
    }

    .link {
      background: #ffffff;
      box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
      border-radius: 6px;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .email {
      display: flex;
      flex-direction: row;
      height: 29.55px;
      background: #ffffff;
      box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
      border-radius: 6px;
      justify-content: center;
      align-items: center;
      margin-left: 14px;
      cursor: pointer;
    }

    .email_text {
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
      text-transform: capitalize;
      color: #4d4d4d;
      margin-right: 7.5px;
    }

    #email {
      margin-left: 9.75px;
      margin-right: 11.65px;
      position: relative;
      top: 2px;
    }

    .toast {
      position: absolute;
      top: 50%;
      left: 50%;
      padding: 11px;
      min-width: 200px;
      transform: translate(-50%, -50%);
      z-index: 3;
      background: rgba(0, 0, 0, 0.7);
      color: #fff;
      border-radius: 4px;
      border: 1px solid #000;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
    }
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    margin-top: 80px;
    margin-bottom: 30px;

    .title {
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 30px;
      text-transform: capitalize;
      color: #414141;
    }

    .info {
      margin-top: 25px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .hashtag {
      cursor: pointer;
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      color: ${(props) => props.positionColor};
      letter-spacing: 1px;
      text-transform: uppercase;

      mix-blend-mode: normal;
      opacity: 0.8;
    }

    .date {
      margin-top: 8px;
      display: flex;
      flex-direction: row-reverse;
      font-family: 'Optima';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      display: flex;
      align-items: center;
      text-align: right;
      color: #414141;
    }

    .endDate {
      color: ${(props) => (props.endDate !== null ? '#FF3131' : '#007fff')};
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      mix-blend-mode: normal;
      opacity: 0.8;
    }

    .content {
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 32px;
      color: #414141;
      margin-bottom: 80px;
    }

    .to_list {
      margin-top: 80px;
      text-underline-position: under;
      height: 40px;
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 40px;
      text-decoration-line: underline;
      color: #414141;
    }

    .etc {
      display: flex;
      flex-direction: row;
      margin-top: 80px;
    }

    .link {
      background: #ffffff;
      box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
      border-radius: 6px;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .email {
      display: flex;
      flex-direction: row;
      height: 29.55px;
      background: #ffffff;
      box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
      border-radius: 6px;
      justify-content: center;
      align-items: center;
      margin-left: 14px;
      cursor: pointer;
    }

    .email_text {
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
      text-transform: capitalize;
      color: #4d4d4d;
      margin-right: 7.5px;
    }

    #email {
      margin-left: 9.75px;
      margin-right: 11.65px;
      position: relative;
      top: 2px;
    }

    .toast {
      position: absolute;
      top: 50%;
      left: 50%;
      padding: 11px;
      min-width: 200px;
      transform: translate(-50%, -50%);
      z-index: 3;
      background: rgba(0, 0, 0, 0.7);
      color: #fff;
      border-radius: 4px;
      border: 1px solid #000;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
    }
  }
`

const Separator = styled.div`
  @media screen and (min-width: 768px) {
    height: 1px;
    background-color: #ededed;
    margin-top: 40px;
    margin-bottom: 24px;
    width: 100%;
  }

  @media screen and (max-width: 767px) {
    height: 1px;
    background-color: #ededed;
    margin-top: 30px;
    margin-bottom: 24px;
    width: 100%;
  }
`
