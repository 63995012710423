import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import { Navigate } from 'react-router-dom'

function ThumbNail({ name }: ThumbNailType) {
  const [isMoreClicked, setIsMoreClicked] = useState<boolean>(false)

  const renderMore = useCallback(
    (id: number, path: string, name: string) => {
      return (
        <div
          id="more"
          onClick={() => {
            setIsMoreClicked(true)
          }}
        >
          more
          {isMoreClicked && <Navigate to={`/community/${path}`} state={{ id, name }} />}
        </div>
      )
    },
    [isMoreClicked],
  )

  return (
    <Container>
      <div id="name">{name}</div>
      {name === 'BLOG' && renderMore(0, 'blog', 'blogMore')}
      {name === 'RECRUIT' && renderMore(1, 'recruit', 'recruitMore')}
      {name === 'NOTICE' && renderMore(2, 'notice', 'noticeMore')}
    </Container>
  )
}

export default ThumbNail

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 22px;

  #name {
    height: 19px;
    font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 3px;
    text-transform: uppercase;
    color: #004099;
  }

  #more {
    height: 17px;
    cursor: pointer;
    font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 1px;
    color: #cfcfcf;
  }
`
