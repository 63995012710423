import Index from '../pages/community/index'
import SectionController from '../components/pages/community/SectionController'
import SearchController from '../components/pages/community/SearchController'

const Community = {
  name: 'community',
  path: '/community',
  element: <Index />,
  children: [
    { path: ':category', element: <SectionController /> },
    { path: ':category/:id', element: <SearchController /> },
  ],
}

export default Community
