import styled from 'styled-components'

const Wrapper = styled.div`
  @media screen and (min-width: 768px) {
    display: grid;
    grid-template-columns: 48% 48%;
    width: 100%;
    justify-content: space-between;
  }

  @media screen and (max-width: 767px) {
  }
`
export { Wrapper }
