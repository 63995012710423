import Index from '../pages/works'
import SearchPortfolio from '../components/pages/works/SearchPortfolio'

const Works = {
  name: 'works',
  path: '/works',
  element: <Index />,
  children: [{ path: ':id', element: <SearchPortfolio /> }],
}

export default Works
