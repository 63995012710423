import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { customizeDate } from '../../../../lib/customizeDate'
import useWindowSize from '../../../../lib/windowSize'

interface IProps {
  notice: INotice
  nth?: number
}

function NoticeByCommunity({ notice, nth }: IProps) {
  const { title, content, createdAt, titleImage, id } = notice
  const { width } = useWindowSize()

  return (
    <Container>
      {width <= 767 ? (
        <Card titleIMG={titleImage} className={`card_${nth}`}>
          {titleImage && <img src={titleImage} alt="titleIMG" style={nth === 0 ? { marginTop: '27px' } : {}} />}

          <Link to={`/community/notice/${id}`}>
            <div className={`title_box`}>
              <span className="title">{title}</span>
              <span className="more">더보기</span>
            </div>
          </Link>
          <div className="content" dangerouslySetInnerHTML={{ __html: content }} />
          <div className="date">{customizeDate(createdAt)}</div>
        </Card>
      ) : (
        <Card className={`card_${nth}`} titleIMG={titleImage}>
          <div className="left">
            <Link to={`/community/notice/${id}`}>
              <div className={`title_box`} style={nth === 0 ? { marginTop: '56px' } : {}}>
                <span className="title">{title}</span>
                <span className="more">더보기</span>
              </div>
            </Link>
            <div className="content" dangerouslySetInnerHTML={{ __html: content }} />
            <div className="date">{customizeDate(createdAt)}</div>
          </div>

          {titleImage && (
            <div className="right" style={nth === 0 ? { marginTop: '96px', marginBottom: '26px' } : {}}>
              <img src={titleImage} alt="titleIMG" />
            </div>
          )}
        </Card>
      )}
    </Container>
  )
}

export default NoticeByCommunity
const Container = styled.div`
  @media screen and (min-width: 768px) {
    .card_0 {
      height: 206px;
    }
  }
  @media screen and (max-width: 767px) {
    .card_7 {
      border-bottom: none;
    }
  }
`

const Card = styled.div<{ titleIMG: string }>`
  @media screen and (min-width: 768px) {
    border-bottom: 1px solid #dadada;
    height: calc(1093px / 6);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    .title_box {
      margin-left: 32px;
      margin-top: 24px;
      margin-bottom: 16px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .title {
      white-space: nowrap;
      cursor: pointer;
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 30px;
      text-transform: capitalize;
      color: #414141;
    }

    .more {
      cursor: pointer;
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 30px;
      text-decoration-line: underline;
      text-transform: capitalize;
      color: #979797;
      margin-left: 8px;
      text-underline-position: under;
      white-space: nowrap;
    }

    .content {
      width: 100%;
      margin-left: 32px;
      height: 52px;
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 26px;
      text-transform: capitalize;
      color: #a8a8a8;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      margin-right: 16px;
    }

    .date {
      margin-left: 32px;
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      text-transform: capitalize;
      color: #a8a8a8;
      margin-top: 10px;
      width: fit-content;
    }

    .left {
      width: 85%;
    }

    .right {
      margin-right: 34px;
      margin-top: 50px;
      margin-bottom: 40px;
    }

    .right img {
      width: 84px;
      height: 84px;
    }
  }

  @media screen and (max-width: 767px) {
    margin-left: 32px;
    margin-right: 32px;
    border-bottom: 1px solid #dadada;
    height: auto;
    display: flex;
    flex-direction: column;

    img {
      width: 72px;
      height: 72px;
      margin-top: 40px;
      margin-bottom: 16px;
    }

    .title_box {
      margin-top: ${(props) => (props.titleIMG === null ? 40 : 16)}px;
      margin-bottom: 16px;
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;
    }

    .title {
      width: 214px;
      cursor: pointer;
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 30px;
      text-transform: capitalize;
      color: #414141;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }

    .more {
      cursor: pointer;
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 30px;
      text-decoration-line: underline;
      text-transform: capitalize;
      color: #979797;
      margin-left: 8px;
      text-underline-position: under;
      white-space: nowrap;
      position: absolute;
      bottom: 0;
      right: 0;
    }

    .content {
      height: 78px;
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 26px;
      text-transform: capitalize;
      color: #a8a8a8;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      margin-bottom: 4px;
    }

    .date {
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      text-align: right;
      text-transform: capitalize;
      color: #a8a8a8;
      width: fit-content;
      margin-bottom: 24px;
    }
  }
`
