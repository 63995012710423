import { useLocation } from 'react-router-dom'
import styled, { css } from 'styled-components'
import Layout from '../../components/layouts/MainPageLayout'
import Blog from '../../components/pages/community/blog/Blog'
import NaverMap from '../../components/NaverMap'
import { aboutContents } from '../../components/pages/about/aboutContents'
import AboutPart from '../../components/pages/about/AboutPart'
import ThumbNail from '../../components/pages/main/ThumbNail'
import MainIntro from '../../components/pages/main/MainIntro'
import AllPortfolios from '../../components/pages/main/AllPortfolios'
import { useBlog } from '../../models/useBlog'
import { usePosts } from '../../models/usePosts'
import { useRecruits } from '../../models/useRecruits'
import { useNotice } from '../../models/useNotice'
import Recruit from '../../components/pages/community/recruit/Recruit'
import Notice from '../../components/pages/community/notice/Notice'
import { useLazyRequest } from '../../hooks/useIntersectionObserver'
import { useState } from 'react'

function Index() {
  const location = useLocation()
  const searchParamsWork = new URLSearchParams(location.search).get('work')

  const [blogLoaded, setBlogLoaded] = useState<boolean>(false)
  const [noticeLoaded, setNoticeLoaded] = useState<boolean>(false)
  const [recruitLoaded, setRecruitLoaded] = useState<boolean>(false)

  const blogRef = useLazyRequest((entry, observer) => {
    observer.unobserve(entry.target)
    setBlogLoaded(true)
  })

  const noticeRef = useLazyRequest((entry, observer) => {
    observer.unobserve(entry.target)
    setNoticeLoaded(true)
  })

  const recruitRef = useLazyRequest((entry, observer) => {
    observer.unobserve(entry.target)
    setRecruitLoaded(true)
  })

  const { data: blogsData } = useBlog({ start: 0, perPage: 4 }, blogLoaded)
  const { data: portfoliosData } = usePosts({ start: 0, perPage: 4, type: searchParamsWork as Type })
  const { data: recruitsData } = useRecruits({ start: 0, perPage: 4 }, recruitLoaded)
  const { data: noticesData } = useNotice({ start: 0, perPage: 4 }, noticeLoaded)

  const blogs = blogsData?.data.data
  const recruits = recruitsData?.data.data
  const notices = noticesData?.data.data

  return (
    <Layout>
      <Scene1>
        {/*메인->WORKS*/}
        <Indicator name="works">WORKS</Indicator>
        <MainIntro />
        <AllPortfolios portfolios={portfoliosData?.data.data} />
      </Scene1>
      <Separator />
      <Scene2>
        {/*메인-> ABOUTS*/}
        <Indicator name="about">ABOUT</Indicator>
        <Abouts>
          {aboutContents.map((content, index) => (
            <AboutPart item={content} key={index} />
          ))}
        </Abouts>
        {/*커뮤니티 썸네일*/}
        <ThumbNail name="BLOG" />
        <div ref={blogRef}></div>
        <Indicator name="community">COMMUNITY</Indicator>
        <BlogContainer>
          {blogs?.map((item, index) => (
            <Blog blog={item} key={index} />
          ))}
        </BlogContainer>
        {/* Recruit 썸네일*/}
        <ThumbNail name="RECRUIT" />
        <div ref={recruitRef}></div>
        <RecruitContainer>
          {recruits?.map((item, index) => (
            <Recruit recruit={item} key={index} num={index} />
          ))}
        </RecruitContainer>
        {/* Notice 썸네일*/}
        <ThumbNail name="NOTICE" />
        <div ref={noticeRef}></div>
        <NoticeContainer>
          {notices?.map((item, index) => (
            <Notice notice={item} key={index} />
          ))}
        </NoticeContainer>
        <Indicator name="map">MAP</Indicator>
        <NaverMap />
      </Scene2>
    </Layout>
  )
}

export default Index

const Abouts = styled.div`
  @media screen and (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    column-gap: 2.7778vw;
    align-items: start;
    margin-bottom: 145px;
  }

  @media screen and (max-width: 767px) {
    margin-top: 80px;
    display: flex;
    flex-direction: column;
  }
`

const BlogContainer = styled.div`
  @media screen and (min-width: 768px) {
    margin-bottom: 92.68px;
    display: grid;
    justify-content: space-between;
    grid-template-columns: 48% 48%;
    .card_nth_0,
    .card_nth_1 {
      margin-bottom: 60px;
    }
  }

  @media screen and (max-width: 767px) {
    margin-bottom: 29px;
    margin-top: 58px;
  }
`

const NoticeContainer = styled.div`
  @media screen and (min-width: 768px) {
    display: grid;
    justify-content: space-between;
    grid-template-columns: 48% 48%;
    .card_nth_0,
    .card_nth_1 {
      margin-bottom: 60px;
    }
  }

  @media screen and (max-width: 767px) {
    margin-bottom: 47px;
  }
`

const RecruitContainer = styled.div`
  @media screen and (min-width: 768px) {
    margin-bottom: 146px;
  }

  @media screen and (max-width: 767px) {
    margin-bottom: 48px;
  }
`

const Scene1 = styled.div`
  @media screen and (min-width: 768px) {
    margin-left: 13vw;
    margin-right: 13vw;
  }

  @media screen and (max-width: 767px) {
    margin-left: 8vw;
    margin-right: 8vw;
  }
`
const Scene2 = styled.div`
  @media screen and (min-width: 768px) {
    margin-left: 13vw;
    margin-right: 13vw;
  }

  @media screen and (max-width: 767px) {
    margin-left: 8vw;
    margin-right: 8vw;
  }
`

const Separator = styled.div`
  @media screen and (min-width: 768px) {
    height: 2px;
    background-color: #ededed;
    margin-top: 95px;
    margin-bottom: 144px;
    width: 100%;
  }

  @media screen and (max-width: 767px) {
    margin-top: 32px;
    height: 2px;
    background-color: #ededed;
    width: 100%;
  }
`

const Indicator = styled.div<{ name: string }>`
  @media screen and (min-width: 768px) {
    position: relative;
    width: 58px;
    height: 16px;
    right: 72px;
    ${({ name }) =>
      name === 'about' &&
      css`
        top: 36px;
      `}
    ${({ name }) =>
      name === 'community' &&
      css`
        top: 75px;
      `}
    ${({ name }) =>
      name === 'map' &&
      css`
        top: 325px;
      `}

    ${({ name }) =>
      name === 'works' &&
      css`
        top: 75px;
      `}

    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 0.65vw;
    line-height: 16px;
    text-align: right;
    letter-spacing: 3px;
    text-transform: uppercase;
    color: #979797;
    transform: rotate(-90deg);
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
`
