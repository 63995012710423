import styled from 'styled-components'
import { Link, useLocation, useNavigate } from 'react-router-dom'

interface IProps {
  portfolio: IWork
}

function PortFolio({ portfolio }: IProps) {
  const { hashtags, id, title, titleImage } = portfolio
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const searchParamsType = new URLSearchParams(location.search).get('type')

  return (
    <Card pathname={pathname}>
      <Link to={`/works/${id}`}>
        <div style={{ position: 'relative', width: '100%', paddingBottom: '100%', overflow: 'hidden' }}>
          <img src={titleImage} alt="mainIMG" />
        </div>
      </Link>
      <div className="tag">
        {hashtags.map((item: HashtagType) => (
          <span
            key={item.id}
            onClick={() => {
              if (!searchParamsType) navigate(`?hashtagId=${item.id}`)
              else navigate(`?type=${searchParamsType}&hashtagId=${item.id}`)
            }}
          >
            {item.name}&nbsp;
          </span>
        ))}
      </div>
      <Link to={`/works/${id}`}>
        <div className="title">{title}</div>{' '}
      </Link>
    </Card>
  )
}

export default PortFolio

const Card = styled.div<{ pathname: string }>`
  @media screen and (min-width: 768px) {
    width: 100%;
    .tag {
      height: 20px;
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 20px;
      color: #004099;
      margin-top: 20px;
      margin-bottom: 16px;
      cursor: pointer;
    }

    .title {
      cursor: pointer;
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 1.45vw;
      line-height: 40px;
      color: #414141;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      margin-bottom: 64px;
    }

    img {
      cursor: pointer;
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }

  @media screen and (max-width: 767px) {
    padding-bottom: 1px;
    img {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    .tag {
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 20px;
      color: #004099;
      margin-top: 16px;
      margin-bottom: 16px;
    }

    .title {
      margin-bottom: ${(props) => (props.pathname === '/' ? 48 : 30)}px;
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 28px;
      line-height: 40px;
      color: #414141;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
    }
  }
`
