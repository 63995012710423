import React from 'react'
import styled from 'styled-components'
import aboutIMG1 from '../../../assets/images/about_img1.png'
import aboutIMG2 from '../../../assets/images/about_img2.png'
import aboutIMG3 from '../../../assets/images/about_img3.png'
import aboutIMG4 from '../../../assets/images/about_img4.png'
import useWindowSize from '../../../lib/windowSize'

const AboutSection = () => {
  const { width } = useWindowSize()
  return (
    <Container>
      <div className="article">
        <div className="article_img">
          <img id="aboutIMG1" src={aboutIMG1} alt="aboutIMG" />
        </div>
        <div className="content">
          {width <= 767 ? (
            <>
              <div className="title">UX/UI</div>

              <div className="main_text_kr">
                우리의 구성원은 다양한 산업에서 100여개 이상의 국 내외 고객사들과 함께 호흡하며 성장하고 있습니다. 매출
                증대, 비용 절감 등 다양한 KPI 달성을 위해 자동화 및 스마트 컴퓨팅 솔루션들이 필요해 집니다. 간단한 웹
                디자인에서부터 복잡한 세일즈 관리 어플리케이션 까지, 모든 개발과제는 최상의 가성비가 나올 수 있도 록
                접근방식을 고민하고 설계하며 개발합니다.
              </div>
            </>
          ) : (
            <>
              <div className="title">Service Prototype</div>
              <div className="main_text_kr">
                위플래닛은 모바일, 웹으로 구성된 서비스 프로토타입 개발에 특화된 팀을 보유하고 있습니다. 기능 정의,
                <br /> 화면 설계 및 디자인, 개발과 런칭까지 책임지고 진행하고 있습니다. 사업에 꼭 필요한 분야에 집중하실
                수<br /> 있도록, 위플래닛의 기획, 디자인, 개발의 전문가들이 여러분과 함께 합니다.
              </div>
            </>
          )}
        </div>
      </div>
      <div className="article">
        <div className="article_img">
          <img id="aboutIMG2" src={aboutIMG2} alt="aboutIMG2" />
        </div>
        <div className="content">
          {width <= 767 ? (
            <>
              <div className="title">Motion</div>

              <div className="main_text_kr">
                애자일/스크럼 방식의 개발은 소프트웨어 어플리케이션 개발에서 변화를 효과적으로 대응할 수 있습니다. 모든
                분야에서 비용을 조절하고 어플리케이션 기능을 지속적으로 개선할 수 있는 훌륭한 솔루션입니다.
              </div>
            </>
          ) : (
            <>
              <div className="title">Agile Development</div>
              <div className="main_text_kr">
                성공적인 프로토타입 개발을 위해 저희는 애자일한 방식으로 프로젝트를 진행합니다.
                <br />
                최소한의 기간 단위로 계획을 세우고, 기능을 개발하고, 서비스를 개선시켜나갑니다. 이를 통해 개발 과정을
                <br /> 투명하게 공유하고, 프로젝트 실패 리스크를 줄이며, 고객의 요구 변화에 유연하고
                <br /> 신속하게 대응하고자 노력합니다.
              </div>
            </>
          )}
        </div>
      </div>
      <div className="article">
        <div className="article_img">
          <img id="aboutIMG3" src={aboutIMG3} alt="aboutIMG3" />
        </div>
        <div className="content">
          {width <= 767 ? (
            <>
              <div className="title">Branding</div>
              <div className="main_text_kr">
                제품홍보+바이럴+고객확보를 위한 브랜딩 컨설턴트입니다. 110만회원의 추천리뷰! 매달 마케팅 지원 혜택을
                드려요. 실시간 전화상담/상담문의/서비스를 진행합니다. 컨설팅문의, 국내마케팅, 해외마케팅 관련한 업무
                등을 하고 있습니다.
              </div>
            </>
          ) : (
            <>
              <div className="title">Tech Stack</div>
              <div className="main_text_kr">
                프로젝트의 장기적인 관리를 위해 보편적이고, 최신화된 기술을 사용합니다. 클라우드 인프라(AWS) 위에 <br />
                Docker, NodeJS(Express, Nest)로 백엔드를 구성하고, JS 프레임워크 / 라이브러리(ReactJS,
                <br />
                NextJS, VueJS, NuxtJS, React Native)로 프론트엔드를 개발합니다.
                <br /> 모바일 네이티브는 Kotlin(MVVM), Swift로 개발하고 있습니다.
              </div>
            </>
          )}
        </div>
      </div>
      <div className="article">
        <div className="article_img">
          <img id="aboutIMG4" src={aboutIMG4} alt="aboutIMG4" />
        </div>
        <div className="content">
          {width <= 767 ? (
            <>
              <div className="title">Development</div>
              <div className="main_text_kr">
                SMS(시스템 유지 보수 서비스, System Maintenance Service)는 플랫폼이 안전하게 보호되고 업데이트되며
                최상의 성능을 발휘하도록 보장합니다.
              </div>
            </>
          ) : (
            <>
              <div className="title">Design &#38; UX</div>
              <div className="main_text_kr">
                OS별 디자인 가이드라인을 준수하고, 사용자에게 가장 익숙한 방식의 UX를 만들어내기 위해 노력합니다.
                <br />
                독특함보다는 보편성을 추구하며, 개발 친화적인 디자인을 선호합니다. 로고를 통함한 브랜드 디자인,
                <br /> 웹 및 모바일 화면 디자인, 서비스 운영을 위한 2D 컨텐츠 디자인을 지원합니다.
                <br />
                Sketch / Zeplin / Figma를 사용합니다.
              </div>
            </>
          )}
        </div>
      </div>
    </Container>
  )
}

export default AboutSection

const Container = styled.div`
  @media screen and (min-width: 768px) {
    margin-bottom: 90px;
    display: flex;
    flex-direction: column;

    .article:first-child {
      width: 108%;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      margin-bottom: 171px;
      align-items: center;
      margin-left: 1.4vw;
      margin-right: 1.4vw;
    }

    .article:first-child .title {
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 28px;
      line-height: 30px;
      color: #000000;
      margin-bottom: 32px;
    }

    .article:first-child .main_text_en {
      font-family: 'Optima';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 30px;
      color: #000000;
      margin-bottom: 32px;
    }

    .article:first-child .main_text_kr {
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 27px;
      color: #000000;
      mix-blend-mode: normal;
      opacity: 0.8;
    }

    .article:first-child .article_img {
      position: relative;
      bottom: 20px;
    }

    .article:nth-child(2) {
      width: 108%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      margin-bottom: 117px;
      align-items: center;
      margin-left: 1.4vw;
      margin-right: 1.4vw;
    }

    .article:nth-child(2) .title {
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 28px;
      line-height: 30px;
      color: #000000;
      margin-bottom: 32px;
    }

    .article:nth-child(2) .content {
      text-align: right;
    }

    .article:nth-child(2) .main_text_en {
      font-family: 'Optima';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 30px;

      text-align: right;
      margin-bottom: 20px;
      color: #000000;
    }

    .article:nth-child(2) .main_text_kr {
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 27px;
      text-align: right;
      color: #000000;
      mix-blend-mode: normal;
      opacity: 0.8;
    }

    .article:nth-child(3) {
      width: 108%;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      margin-bottom: 117px;
      align-items: center;
      margin-left: 1.4vw;
      margin-right: 1.4vw;
    }

    .article:nth-child(3) .title {
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 28px;
      line-height: 30px;
      color: #000000;
      margin-bottom: 32px;
    }

    .article:nth-child(3) .main_text_en {
      font-family: 'Optima';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 30px;
      color: #000000;
      margin-bottom: 47px;
    }

    .article:nth-child(3) .main_text_kr {
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 27px;
      color: #000000;
      mix-blend-mode: normal;
      opacity: 0.8;
    }

    .article:nth-child(3) .article_img {
      position: relative;
      bottom: 28px;
    }

    .article:nth-child(4) {
      width: 108%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      margin-bottom: 90px;
      align-items: center;
      margin-left: 1.4vw;
      margin-right: 1.4vw;
    }

    .article:nth-child(4) .title {
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 28px;
      line-height: 30px;
      color: #000000;
      margin-bottom: 32px;
    }

    .article:nth-child(4) .content {
      text-align: right;
    }

    .article:nth-child(4) .main_text_en {
      position: relative;

      font-family: 'Optima';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 30px;

      text-align: right;
      margin-bottom: 20px;
      color: #000000;
    }

    .article:nth-child(4) .main_text_kr {
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 27px;
      text-align: right;
      color: #000000;
      mix-blend-mode: normal;
      opacity: 0.8;
    }
  }

  @media screen and (max-width: 767px) {
    .article {
      margin-bottom: 80px;
    }

    .article:first-child {
      margin-top: 80px;
    }

    .article_img {
      display: flex;
      justify-content: center;
      margin-bottom: 30px;
    }

    .title {
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 28px;
      line-height: 30px;
      color: #000000;
      margin-bottom: 16px;
    }

    .content {
    }

    .main_text_en {
      font-family: 'Optima';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 30px;
      color: #000000;
      margin-bottom: 16px;
    }

    .main_text_kr {
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 27px;
      color: #000000;
      mix-blend-mode: normal;
      opacity: 0.8;
    }
  }
`
