export const blogQueryKey = {
  blog: ['blog'],
  blogInfinite: ['blog', 'infinite'],
  blogId: (id: number) => ['blog', id],
}

export const postsQueryKey = {
  posts: ['posts'],
  blogInfinite: ['posts', 'infinite'],
  postsId: (id: number) => ['posts', id],
}

export const recruitsQueryKey = {
  recruits: ['recruits'],
  recruitsInfinite: ['recruits', 'infinite'],
  recruitsId: (id: number) => ['recruits', id],
}

export const noticeQueryKey = {
  notice: ['notice'],
  noticeInfinite: ['notice', 'infinite'],
  noticeId: (id: number) => ['notice', id],
}

export const membersQueryKey = {
  members: ['members'],
  membersInfinite: ['members', 'infinite'],
}
