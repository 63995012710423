import styled from 'styled-components'
import useWindowSize from '../../../../lib/windowSize'
import Blog from './Blog'
import { useInfiniteBlog } from '../../../../models/useBlog'
import useInfiniteScroll from '../../../../hooks/useInfiniteScroll'
import HashtagState from '../../../HashtagState'

const BlogSection = () => {
  const { width } = useWindowSize()

  const searchParamsHashtagId = new URLSearchParams(location.search).get('hashtagId')
  const {
    data: blogsData,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteBlog({
    start: 0,
    perPage: 10,
    hashtagId: searchParamsHashtagId ? Number(searchParamsHashtagId) : undefined,
  })
  const setTarget = useInfiniteScroll({ hasNextPage, isFetchingNextPage, fetchNextPage })

  return (
    <Container>
      <HashtagState<IBlog[]> data={blogsData} path={'/community/blog'} />
      <Blogs>
        <div className={width <= 767 ? 'mobile' : 'desktop'}>
          {blogsData?.pages
            .flatMap((page) => page.data)
            .map((item: IBlog, index: number) => (
              <div key={index}>
                <Blog blog={item} key={index} />
              </div>
            ))}
        </div>
      </Blogs>
      <div ref={setTarget} />
    </Container>
  )
}

export default BlogSection

const Container = styled.div`
  width: 100%;
  @media screen and (min-width: 768px) {
    .desktop {
      display: grid;
      justify-content: space-between;
      grid-template-columns: 48% 48%;
      margin-bottom: 144px;
    }

    .curHashtag {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 29px;
      text-transform: capitalize;
      color: #004099;
      height: 44px;
      width: fit-content;
      border-radius: 40px;
      box-sizing: border-box;
      border: 1px solid #004099;
      margin-bottom: 25px;
    }

    .tagName {
      margin-left: 14px;
    }

    #removeBtn {
      margin-left: 9px;
      margin-right: 13px;
      position: relative;
      top: 2.9px;
    }
  }

  @media screen and (max-width: 767px) {
    margin-top: 114px;
    width: 100%;

    .curHashtag {
      position: absolute;
      top: 216px;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 29px;
      text-transform: capitalize;
      color: #004099;
      height: 44px;
      width: fit-content;
      border-radius: 40px;
      box-sizing: border-box;
      border: 1px solid #004099;
    }

    .tagName {
      margin-left: 14px;
    }

    #removeBtn {
      margin-left: 9px;
      margin-right: 13px;
      position: relative;
      top: 2.9px;
    }

    .mobile {
      margin-bottom: 30px;
    }
  }
`

const Blogs = styled.div`
  @media screen and (min-width: 768px) {
    margin-top: 11px;
  }

  @media screen and (max-width: 767px) {
  }
`
