import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import headerIconSvg from '../../../assets/icon/headerIcon.svg'
import { Link, useLocation } from 'react-router-dom'
import hambergerBlue from '../../../assets/icon/hambergerBlue.svg'
import hambergerWhite from '../../../assets/icon/hambergerWhite.svg'
import HeaderModal from './HeaderModal'
import useWindowSize from '../../../lib/windowSize'

function Index() {
  const { pathname } = useLocation()
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [animationOn, setAnimationOn] = useState<boolean>(false)
  const [scrollPosition, setScrollPosition] = useState(0)
  const { width } = useWindowSize()
  const el = useRef()

  useEffect(() => {
    window.addEventListener('scroll', updateScroll)

    return () => {
      window.removeEventListener('scroll', updateScroll)
    }
  })

  useEffect(() => {
    window.addEventListener('click', handleCloseModal)

    return () => {
      window.removeEventListener('click', handleCloseModal)
    }
  }, [modalOpen])

  const closeModal = (): void => {
    setModalOpen(false)
  }

  const updateScroll = () => {
    setScrollPosition(window.scrollY || document.documentElement.scrollTop)
  }

  const handleCloseModal = (e: MouseEvent) => {
    if (modalOpen && e.target !== el.current) {
      setAnimationOn(true)
      setTimeout(() => {
        setAnimationOn(false)
        setModalOpen(false)
      }, 500)
    }
  }

  return (
    <Container
      path={pathname}
      style={pathname !== '/' && scrollPosition < 50 && width > 767 ? { height: '192px' } : { height: '100px' }}
    >
      {pathname === '/' ? (
        <>
          {' '}
          <BackLine style={{ left: '13vw' }} />
          <BackLine style={{ left: '31.25vw' }} />
          <BackLine style={{ left: '50%', right: '50%' }} />
          <BackLine style={{ right: '31.25vw' }} />
          <BackLine style={{ right: '13vw' }} />
        </>
      ) : (
        <></>
      )}

      {pathname === '/' ? (
        <div
          className="hamberger"
          onClick={() => {
            setModalOpen(true)
          }}
        >
          <img id="hamberger" src={hambergerBlue} alt="hamberger" />
        </div>
      ) : (
        <div
          className="hamberger"
          onClick={() => {
            setModalOpen(true)
          }}
        >
          <img id="hamberger" src={hambergerWhite} alt="hamberger" />
        </div>
      )}

      <HeaderLayout path={pathname}>
        <li
          id="works"
          style={scrollPosition < 50 && width > 767 ? { top: '118px' } : pathname !== '/' ? { top: '46.5px' } : {}}
        >
          <Link to="/works">WORKS</Link>
          <Pointer style={pathname.indexOf('works') >= 0 ? { display: 'block' } : { display: 'none' }} />
        </li>
        <li
          id="about"
          style={scrollPosition < 50 && width > 767 ? { top: '118px' } : pathname !== '/' ? { top: '46.5px' } : {}}
        >
          {' '}
          <Link to="/about">ABOUT</Link>
          <Pointer style={pathname.indexOf('about') >= 0 ? { display: 'block' } : { display: 'none' }} />
        </li>
        <Link to="/">
          <img
            id="headerIcon"
            src={headerIconSvg}
            alt="headerIcon"
            style={
              scrollPosition < 50 && width > 767
                ? { top: '95px' }
                : pathname !== '/' && width > 767
                ? { top: '20px' }
                : {}
            }
          />
        </Link>
        <li
          id="community"
          style={scrollPosition < 50 && width > 767 ? { top: '118px' } : pathname !== '/' ? { top: '46.5px' } : {}}
        >
          {' '}
          <Link to="/community/blog">COMMUNITY</Link>
          <Pointer style={pathname.indexOf('community') >= 0 ? { display: 'block' } : { display: 'none' }} />
        </li>
        <li
          id="contacts"
          style={scrollPosition < 50 && width > 767 ? { top: '118px' } : pathname !== '/' ? { top: '46.5px' } : {}}
        >
          {' '}
          <Link to="/contacts">CONTACTS</Link>
          <Pointer style={pathname.indexOf('contacts') >= 0 ? { display: 'block' } : { display: 'none' }} />
        </li>
      </HeaderLayout>

      <HeaderModal open={modalOpen} close={closeModal} refData={el} animationOn={animationOn} />
    </Container>
  )
}

export default Index

//style 박스 자체는 반응형 안에 폰트들은 픽셀 크기 유지
const Container = styled.div<{ path: string }>`
  @media screen and (min-width: 767px) {
    left: 0;
    top: 0;
    display: flex;
    width: 100%;
    position: ${(props) => (props.path === '/' ? 'absolute' : 'fixed')};
    background-color: ${(props) => (props.path === '/' ? 'white' : props.theme.colors.primary)};
    z-index: 9;

    .hamberger {
      display: none;
    }
  }

  @media screen and (max-width: 767px) {
    height: ${(props) => (props.path === '/' ? 152 : 112)}px;
    width: 100%;
    position: ${(props) => (props.path === '/' ? 'absolute' : 'fixed')};
    left: 0;
    top: 0;
    background-color: ${(props) => (props.path === '/' ? 'white' : props.theme.colors.primary)};
    z-index: 9;

    .hamberger img {
      position: absolute;
      top: ${(props) => (props.path === '/' ? 70 : 50)}px;
      left: 8vw;
    }
  }
`
const HeaderLayout = styled.ul<{ path: string }>`
  @media screen and (min-width: 767px) {
    flex-direction: row;
    font-style: normal;
    font-weight: 800;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 3px;
    text-transform: uppercase;
    color: ${(props) => (props.path === '/' ? props.theme.colors.primary : 'white')};
    display: flex;
    flex-direction: row;
    width: 100%;

    .hamberger {
      display: none;
    }

    img {
      position: absolute;
      left: calc(50% - 34px);
      z-index: 10;
      top: 92px;
    }

    li:nth-child(1) {
      position: absolute;
      left: 13.2vw;
      top: 118px;
    }

    li:nth-child(5) {
      position: absolute;
      right: 13vw;
      top: 118px;
    }

    li:nth-child(2) {
      position: absolute;
      left: 23vw;
      top: 118px;
    }

    li:nth-child(4) {
      position: absolute;
      right: 25vw;
      top: 118px;
    }

    li:hover {
      font-size: 13px;
      transition-duration: 0.1s;
    }
  }

  @media screen and (max-width: 767px) {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    li {
      display: none;
    }

    img {
      position: ${(props) => (props.path === '/' ? 'relative' : 'relative')};
      filter: drop-shadow(1px 1px 8px rgba(0, 0, 0, 0.16));
      top: ${(props) => (props.path === '/' ? 25 : 8)}px;
    }
  }
`

const BackLine = styled.div`
  @media screen and (min-width: 768px) {
    top: 0px;
    background: #ededed;
    position: absolute;
    width: 1px;
    height: 269px;
    z-index: 0;
  }

  @media screen and (max-width: 767px) {
  }
`

const Pointer = styled.div`
  @media screen and (min-width: 768px) {
    width: 4px;
    height: 4px;
    border-radius: 100%;
    background: #ffffff;
    margin-top: 6px;
    position: relative;
    left: 50%;
    display: none;
  }
`
