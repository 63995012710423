import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import { getBlogById, getBlogs } from './main'
import { blogQueryKey } from '../constants/query-key'

export const useBlog = (params: Params, loaded: boolean) => {
  return useQuery([...blogQueryKey.blog, params], () => getBlogs(params), {
    enabled: loaded,
  })
}

export const useBlogId = (id: number) => {
  return useQuery(blogQueryKey.blogId(id), () => getBlogById(id))
}

export const useInfiniteBlog = (params: Params) => {
  return useInfiniteQuery({
    queryKey: [...blogQueryKey.blogInfinite, params],
    queryFn: async ({ pageParam = 0 }) => {
      const { data } = await getBlogs({
        ...params,
        start: pageParam,
      })

      return {
        data: data.data,
        nextPage: pageParam,
        total: data.total,
      }
    },
    getNextPageParam: (lastPage) => {
      if (lastPage.nextPage >= lastPage.total) {
        return false
      } else {
        return lastPage.nextPage + 10
      }
    },
  })
}
