import React, { useState } from 'react'
import styled from 'styled-components'
import useWindowSize from '../../lib/windowSize'
import twitterBlue from '../../assets/icon/twitterBlue.png'
import emailBlue from '../../assets/icon/emailBlue.png'
import instaBlue from '../../assets/icon/instaBlue.png'
import PopupModal from '../../components/PopupModal'
import { postContacts } from '../../models/main'
import SubPageLayout from '../../components/layouts/SubPageLayout'
import { checkContactsForm } from '../../lib/checkContactsForm'

const Index = () => {
  const { width } = useWindowSize()
  const [name, setName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [message, setMessage] = useState<string>('')
  const [modalOpen, setModalOpen] = useState<boolean>(false)

  const onChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value)
  }
  const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
  }
  const onChangeMessage = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value)
  }

  const handleSubmit = () => {
    const isCheckedForm = checkContactsForm(name, email, message)

    if (isCheckedForm) {
      postContacts(name, email, message)
      setName('')
      setEmail('')
      setMessage('')
      setModalOpen(true)
    }
  }

  const closeModal = (): void => {
    setModalOpen(false)
  }

  return (
    <Container>
      <SubPageLayout>
        <Main>
          <div className="contacts_info">
            <div id="contacts">Contacts</div>

            <div id="litter">
              개발 견적 문의, 협력 제안, 입사 문의 등<br /> 궁금한게 있으시다면 언제든 연락주세요. <br />
              빠르게 검토한 후 회신드리겠습니다.
            </div>

            <div className="icons">
              <ul>
                <li>
                  <a target="_blank" href=" https://twitter.com/we_PLANET" rel="noopener noreferrer">
                    <img id="twitterIcon" src={twitterBlue} alt="twitterIcon" />
                  </a>
                </li>
                <li>
                  <a target="_blank" href="mailto:weplanet@wepla.net" rel="noopener noreferrer">
                    <img id="beIcon" src={emailBlue} alt="beIcon" />
                  </a>
                </li>
                <li>
                  <a target="_blank" href=" https://www.instagram.com/weplanet/ " rel="noopener noreferrer">
                    <img id="instagramIcon" src={instaBlue} alt="instagramIcon" />
                  </a>
                </li>
              </ul>
            </div>

            {width > 767 && (
              <>
                <div className="btn_Box">
                  <button
                    className="button"
                    onClick={() => {
                      handleSubmit()
                    }}
                  >
                    Send
                  </button>
                </div>
              </>
            )}
          </div>

          <div className="contacts_form">
            <div className="form_Box">
              <div>
                <div className="info">NAME</div>
                <div>
                  <input className="form" value={name} onChange={onChangeName} placeholder={'이름을 입력해주세요.'} />
                </div>
              </div>
              <div>
                <div className="info">E-MAIL</div>
                <div>
                  <input
                    className="form"
                    value={email}
                    onChange={onChangeEmail}
                    placeholder={'회신드릴 연락 가능한 이메일을 등록해주세요.'}
                  />
                </div>
              </div>
              <div>
                <div className="info">MESSAGE</div>
                <div>
                  <textarea
                    className="textAreaForm"
                    value={message}
                    onChange={onChangeMessage}
                    placeholder={'내용을 입력해주세요. (최대 500자)'}
                  />
                </div>
              </div>
            </div>

            {width <= 767 && (
              <div className="btn_Box">
                <button
                  className="button"
                  onClick={() => {
                    handleSubmit()
                  }}
                >
                  Send
                </button>
              </div>
            )}
          </div>
        </Main>
      </SubPageLayout>
      <div className="copyrightDiv">
        <div id="copyright">© Copyright © 2000 Weplanet</div>
        <div className="aboutDoc">
          <div id="about"> About</div>
          <div id="doc">Documents</div>
        </div>
      </div>
      <PopupModal open={modalOpen} close={closeModal} />
    </Container>
  )
}

export default Index

const Main = styled.div`
  @media screen and (min-width: 768px) {
    display: flex;
    flex-direction: row;
    margin-top: 280px;

    .contacts_info {
      width: 24.7%;
    }

    #contacts {
      font-family: 'Optima';
      font-style: normal;
      font-weight: 400;
      font-size: 28px;
      line-height: 30px;
      margin-bottom: 49px;
      color: #000000;
    }

    #litter {
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 30px;
      color: #414141;
      margin-bottom: 48px;
    }

    .icons ul {
      display: flex;
      flex-direction: row;
    }

    .icons ul li {
      margin-right: 32px;
    }

    .button {
      margin-top: 194px;
      width: 166px;
      height: 56px;
      background-color: transparent !important;
      background-image: none !important;
      border: 1px solid #004099;
      box-sizing: border-box;
      border-radius: 16px;
      font-family: 'Optima';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 30px;
      color: #004099;
    }

    .contacts_form {
      margin-top: 82px;
    }

    .info {
      font-family: 'Optima';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 3px;
      text-transform: uppercase;
      color: #000000;
      margin-bottom: 16px;
    }

    .form {
      background: #fafafa;
      mix-blend-mode: normal;
      opacity: 0.9;
      border-radius: 4px 4px 0px 0px;
      height: 54px;
      margin-bottom: 30px;
      width: 51vw;
    }

    .textAreaForm {
      mix-blend-mode: normal;
      opacity: 0.9;
      border-radius: 4px 4px 0px 0px;
      height: 120px;
      background: #fafafa;
    }

    button {
      cursor: pointer;
    }

    input {
      width: 100%;
      padding-left: 12px;
    }

    input::placeholder {
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 30px;
      color: #cfcfcf;
    }

    input:focus {
      outline: none;
    }

    textArea {
      width: 100%;
      padding-left: 12px;
      padding-top: 20px;
    }

    textArea::placeholder {
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #cfcfcf;
    }

    textArea:focus {
      outline: none;
    }
  }

  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
    margin-top: 241px;

    #contacts {
      font-family: 'Optima';
      font-style: normal;
      font-weight: 400;
      font-size: 28px;
      line-height: 30px;
      margin-bottom: 49px;
      color: #000000;
    }

    #litter {
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 30px;
      color: #414141;
      margin-bottom: 48px;
    }

    .icons ul {
      display: flex;
      flex-direction: row;
    }

    .icons ul li {
      margin-right: 32px;
    }

    .btn_Box {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    .button {
      margin-top: 26px;
      width: 166px;
      height: 56px;
      background-color: transparent !important;
      background-image: none !important;
      border: 1px solid #004099;
      box-sizing: border-box;
      border-radius: 16px;
      font-family: 'Optima';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 30px;
      color: #004099;
    }

    .contacts_form {
      margin-top: 61.18px;
      margin-bottom: 60px;
    }

    .info {
      font-family: 'Optima';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 3px;
      text-transform: uppercase;
      color: #000000;
      margin-bottom: 16px;
    }

    .form {
      background: #fafafa;
      mix-blend-mode: normal;
      opacity: 0.9;
      border-radius: 4px 4px 0px 0px;
      height: 54px;
      margin-bottom: 30px;
      padding-left: 12px;
      width: calc(100% - 12px);
    }

    .textAreaForm {
      mix-blend-mode: normal;
      opacity: 0.9;
      border-radius: 4px 4px 0px 0px;
      height: 120px;
      width: 100%;
      background: #fafafa;
      width: calc(100% - 12px);
    }

    button {
      cursor: pointer;
    }

    input {
      width: 100%;
    }

    input::placeholder {
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 30px;
      color: #cfcfcf;
    }

    input:focus {
      outline: none;
    }

    textArea {
      width: 100%;
      padding-left: 12px;
      padding-top: 12px;
    }

    textArea::placeholder {
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 30px;
      color: #cfcfcf;
    }

    textArea:focus {
      outline: none;
    }
  }
`

const Container = styled.div`
  @media screen and (min-width: 768px) {
    margin-left: 13vw;
    margin-right: 13vw;

    .copyrightDiv {
      height: auto;

      display: flex;
      flex-direction: row;
      margin-top: 218px;
      margin-bottom: 20px;
      justify-content: space-between;
    }

    #copyright {
      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 30px;
      color: #979797;
    }

    .aboutDoc {
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 16px;
      line-height: 30px;
      color: #979797;
      display: flex;
      flex-direction: row;
    }

    #about {
      margin-right: 40px;
    }
  }

  @media screen and (max-width: 767px) {
    margin-left: 8vw;
    margin-right: 8vw;

    .copyrightDiv {
      height: auto;
      display: flex;
      flex-direction: column;
      margin-bottom: 32px;
    }

    #copyright {
      font-family: 'Nunito';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 30px;
      color: #000000;
      margin-bottom: 10px;
    }

    .aboutDoc {
      font-family: 'Nunito';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 30px;
      color: #979797;
    }

    #about {
      margin-bottom: 10px;
    }
  }
`
