import { UseInfiniteQueryResult } from '@tanstack/react-query'
import { useEffect, useState } from 'react'

export interface IUseInfiniteScrollProps
  extends Pick<UseInfiniteQueryResult<any>, 'isFetchingNextPage' | 'fetchNextPage' | 'hasNextPage'> {}

function useInfiniteScroll({ hasNextPage, isFetchingNextPage, fetchNextPage }: IUseInfiniteScrollProps) {
  const [target, setTarget] = useState<HTMLDivElement | null>(null)

  useEffect(() => {
    if (!hasNextPage || isFetchingNextPage) {
      return
    }

    let observer: IntersectionObserver
    if (target) {
      observer = new IntersectionObserver(async ([entry]) => {
        if (entry.isIntersecting) {
          await fetchNextPage()
        }
      })
      observer.observe(target)
    }
    return () => {
      observer && observer.disconnect()
    }
  }, [target, hasNextPage, isFetchingNextPage])

  return setTarget
}

export default useInfiniteScroll
