import Index from '../pages/about'
import MembersSection from '../components/pages/about/MembersSection'

const About = {
  name: 'about',
  path: '/about',
  element: <Index />,
  children: [{ path: ':category', element: <MembersSection /> }],
}

export default About
