import buttons from '../common/buttons'

export default {
  403: {
    message: 'Sorry, you are not authorized to access this page.',
    button: buttons.back,
  },
  404: {
    message: 'Sorry, the page you visited does not exist.',
    button: buttons.back,
  },
  500: {
    message: 'Sorry, something went wrong.',
    button: buttons.refresh,
  },
}
