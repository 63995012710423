import BlogSection from './blog/BlogSection'
import RecruitSection from './recruit/RecruitSection'
import NoticeSection from './notice/NoticeSection'
import { useParams } from 'react-router-dom'

const SectionController = () => {
  const params = useParams()

  return (
    <>
      {params.category === 'blog' && <BlogSection />}
      {params.category === 'recruit' && <RecruitSection />}
      {params.category === 'notice' && <NoticeSection />}
    </>
  )
}

export default SectionController
