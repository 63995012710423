import { useParams } from 'react-router-dom'
import SearchRecruit from './recruit/SearchRecruit'
import SearchNotice from './notice/SearchNotice'
import SearchBlog from './blog/SearchBlog'

const SearchController = () => {
  const params = useParams()

  return (
    <>
      {params.category === 'blog' && <SearchBlog />}
      {params.category === 'recruit' && <SearchRecruit />}
      {params.category === 'notice' && <SearchNotice />}
    </>
  )
}

export default SearchController
