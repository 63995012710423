export const aboutContents = [
  {
    id: 1,
    title: 'Service Prototype',
    content:
      '위플래닛은 모바일, 웹으로 구성된 서비스 프로토타입 개발에 특화된 팀을 보유하고 있습니다. 기능 정의, 화면 설계 및 디자인, 개발과 런칭까지 책임지고 진행하고 있습니다. 사업에 꼭 필요한 분야에 집중하실 수 있도록, 위플래닛의 기획, 디자인, 개발의 전문가들이 여러분과 함께 합니다.',
  },
  {
    id: 2,
    title: 'Agile Development',
    content: `성공적인 프로토타입 개발을 위해 저희는 애자일한 방식으로 프로젝트를 진행합니다.최소한의 기간 단위로 계획을 세우고, 기능을 개발하고, 서비스를 개선시켜나갑니다. 이를 통해 
              개발 과정을투명하게 공유하고, 프로젝트 실패 리스크를 줄이며,
              고객의 요구 변화에 유연하고신속하게 대응하고자 노력합니다.`,
  },
  {
    id: 3,
    title: 'Tech Stack',
    content:
      '프로젝트의 장기적인 관리를 위해 보편적이고, 최신화된 기술을 사용합니다. 클라우드 인프라(AWS) 위에Docker, NodeJS(Express, Nest)로 백엔드를 구성하고, JS 프레임워크 / 라이브러리(ReactJS,NextJS, VueJS, NuxtJS, React Native)로 프론트엔드를 개발합니다.모바일 네이티브는 Kotlin(MVVM), Swift로 개발하고 있습니다.',
  },
  {
    id: 4,
    title: 'Design & UX',
    content:
      'OS별 디자인 가이드라인을 준수하고, 사용자에게 가장 익숙한 방식의 UX를 만들어내기 위해 노력합니다.독특함보다는 보편성을 추구하며, 개발 친화적인 디자인을 선호합니다. 로고를 통함한 브랜드 디자인,웹 및 모바일 화면 디자인, 서비스 운영을 위한 2D 컨텐츠 디자인을 지원합니다.Sketch / Zeplin / Figma를 사용합니다.',
  },
]
