import styled from 'styled-components'
import useWindowSize from '../../../../lib/windowSize'
import NoticeByCommunity from './NoticeByCommunity'
import { useNavigate } from 'react-router-dom'
import { useInfiniteNotice } from '../../../../models/useNotice'
import { useEffect, useState } from 'react'
import useInfiniteScroll from '../../../../hooks/useInfiniteScroll'

const NoticeSection = () => {
  const navigate = useNavigate()
  const { width } = useWindowSize()

  const searchParams = new URLSearchParams(location.search)
  const {
    data: noticesData,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteNotice({
    start: 0,
    perPage: 10,
    hashtagId: searchParams.get('hashtagId') ? Number(searchParams.get('hashtagId')) : undefined,
  })
  const setTarget = useInfiniteScroll({ hasNextPage, isFetchingNextPage, fetchNextPage })

  const [hashtagArr, setHashtagArr] = useState<{ id?: number; name: string }[]>([])

  useEffect(() => {
    let hashtagArrTmp: { id?: number; name: string }[] = [{ name: '#ALL' }]

    noticesData?.pages
      .flatMap((page) => page.data)
      .map((item: INotice) => {
        item.hashtags.map((item) => {
          return hashtagArrTmp.push(item)
        })

        return setHashtagArr(hashtagArrTmp)
      })
  }, [noticesData])

  return (
    <Container>
      <div className="position_filter">
        {hashtagArr.map((item, index) => (
          <div
            className="position"
            key={index}
            onClick={() => {
              navigate(typeof item.id === 'number' ? `/community/notice?hashtagId=${item.id}` : `/community/notice`)
            }}
            style={
              !searchParams.get('hashtagId') && !item.id
                ? { color: '#004099' }
                : item.id === Number(searchParams.get('hashtagId'))
                ? { color: '#004099' }
                : { color: '#CFCFCF' }
            }
          >
            {item.name}
          </div>
        ))}
      </div>

      <Notices>
        <div className={width <= 767 ? 'mobile' : 'desktop'}>
          {noticesData?.pages
            .flatMap((page) => page.data)
            .map((item: INotice, index: number) => (
              <div key={index}>
                <NoticeByCommunity notice={item} nth={index} />
              </div>
            ))}
        </div>
      </Notices>
      <div ref={setTarget} />
    </Container>
  )
}

export default NoticeSection

const Container = styled.div`
  @media screen and (min-width: 768px) {
    width: 100%;

    .position_filter {
      margin-bottom: 32px;
      display: flex;
      flex-direction: row;
      height: 14px;
    }

    .position {
      cursor: pointer;
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 0.75vw;
      line-height: 14px;
      letter-spacing: 1.6px;
      text-transform: uppercase;
      margin-right: 15px;
    }
  }

  @media screen and (max-width: 767px) {
    .position_filter {
      margin-top: 81px;
      margin-bottom: 30px;
    }

    .position {
      display: inline-block;
      margin-right: 15px;
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 1.6px;
      text-transform: uppercase;
    }
  }
`
const Notices = styled.div`
  @media screen and (min-width: 768px) {
    .desktop {
      margin-bottom: 144px;
      background: #ffffff;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    }
  }

  @media screen and (max-width: 767px) {
    .mobile {
      background: #ffffff;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
      margin-bottom: 29px;
    }
  }
`
