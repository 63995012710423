import styled from 'styled-components'
import { Link, useLocation, useNavigate } from 'react-router-dom'

interface IProps {
  blog: IBlog
  setHashtag?: any
}

function Blog({ blog, setHashtag }: IProps) {
  const { id, hashtags, title, titleImage } = blog
  const { pathname } = useLocation()
  const navigate = useNavigate()

  return (
    <Card pathname={pathname}>
      <Link to={`/community/blog/${id}`}>
        <div style={{ position: 'relative', width: '100%', paddingBottom: '100%', overflow: 'hidden' }}>
          <img src={titleImage} alt="mainIMG" />
        </div>
      </Link>
      <div className="tag">
        {hashtags.map((item: HashtagType) => (
          <span
            key={item.id}
            onClick={() => {
              setHashtag && setHashtag(item.id)
              navigate(`/community/blog?hashtagId=${item.id}`)
            }}
          >
            {item.name}&nbsp;
          </span>
        ))}
      </div>
      <Link to={`/community/blog/${id}`}>
        <div className="title">{title}</div>
      </Link>
    </Card>
  )
}

export default Blog

const Card = styled.div<{ pathname: string }>`
  @media screen and (min-width: 768px) {
    width: 100%;

    .tag {
      cursor: pointer;
      height: 20px;
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: ${(props) => (props.pathname === '/' ? 17 : 20)}px;
      line-height: ${(props) => (props.pathname === '/' ? 20 : 24)}px;
      color: #004099;
      margin-top: ${(props) => (props.pathname === '/' ? 20 : 16)}px;
      margin-bottom: 16px;
    }

    .title {
      cursor: pointer;
      margin-bottom: ${(props) => (props.pathname === '/' ? 52.32 : 64)}px;
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 28px;
      line-height: 40px;
      color: #414141;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 767px) {
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }

    .tag {
      /* height: ${(props) => (props.pathname === '/community' ? 24 : 20)}px; */
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: ${(props) => (props.pathname === '/community' ? 20 : 17)}px;
      line-height: ${(props) => (props.pathname === '/community' ? 24 : 20)}px;
      color: #004099;
      margin-top: ${(props) => (props.pathname === '/community' ? 16 : 20)}px;
      margin-bottom: 16px;
      cursor: pointer;
    }

    .title {
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 28px;
      line-height: 40px;
      color: #414141;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      cursor: pointer;
      margin-bottom: ${(props) => (props.pathname === '/community' ? 30 : 52)}px;
    }
  }
`
