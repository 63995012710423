import Index from '../pages/contacts'

const Contacts = {
  name: 'contacts',
  path: '/contacts',
  element: <Index />,
  children: [],
}

export default Contacts
