import CommunityNav from '../../components/pages/community/CommunityNav'
import styled from 'styled-components'
import { Outlet } from 'react-router-dom'
import SubPageLayout from '../../components/layouts/SubPageLayout'

const Index = () => {
  return (
    <SubPageLayout>
      <Main>
        <CommunityNav />
        <Outlet />
      </Main>
    </SubPageLayout>
  )
}

export default Index

const Main = styled.div`
  @media screen and (min-width: 768px) {
    display: flex;
    flex-direction: row;
    margin-top: 269px;
    margin-left: 13vw;
    margin-right: 13vw;
  }

  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
    margin-top: 142px;
    margin-left: 8vw;
    margin-right: 8vw;
  }
`
