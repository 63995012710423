import styled from 'styled-components'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useCallback } from 'react'

const TAB = ['ALL WORKS', 'WEPLANET', 'FOR CLIENT']

function WorksNav({ setNavIndex, curNavIndex }: { setNavIndex?: any; curNavIndex?: any }) {
  const params = useParams()
  const { pathname } = useLocation()
  const searchParamsType = new URLSearchParams(location.search).get('type')
  const navigate = useNavigate()

  function handleNavClicked(nth: number): void {
    if (nth === 0) navigate('/works')
    if (nth === 1) navigate('/works?type=weplanet')
    if (nth === 2) navigate('/works?type=client')
  }

  const handleNavTab = useCallback(() => {
    return TAB.map((item, index) => (
      <div
        key={item}
        style={
          params.id === undefined
            ? (item === 'ALL WORKS' && searchParamsType !== 'weplanet' && searchParamsType !== 'client') ||
              (item === 'WEPLANET' && searchParamsType === 'weplanet') ||
              (item === 'FOR CLIENT' && searchParamsType === 'client')
              ? { color: '#004099' }
              : { color: '#CFCFCF' }
            : curNavIndex === index
            ? { color: '#004099' }
            : { color: '#CFCFCF' }
        }
        className={`li_${index}`}
        onClick={() => {
          handleNavClicked(index)
          setNavIndex(index)
        }}
      >
        {item}
      </div>
    ))
  }, [curNavIndex, searchParamsType])

  return <Container path={pathname}>{handleNavTab()}</Container>
}

export default WorksNav

const Container = styled.div<{ path: string }>`
  @media screen and (min-width: 768px) {
    min-width: 200px;
    display: ${(props) => (props.path === '/' ? 'none' : 'flex')};
    flex-direction: column;

    div {
      cursor: pointer;
      height: 19px;
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 1vw;
      line-height: 19px;
      letter-spacing: 3px;
      text-transform: uppercase;
      margin-bottom: 2vw;
    }
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    display: ${(props) => (props.path === '/' ? 'none' : 'flex')};
    flex-direction: ${(props) => (props.path.indexOf('works') >= 0 ? 'column' : 'row')};
    ${(props) => (props.path.indexOf('works') >= 0 ? '' : 'justify-content: space-between;')}
    div {
      cursor: pointer;
      height: 19px;
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 3px;
      text-transform: uppercase;
      color: #cfcfcf;
      margin-bottom: 10px;
    }

    div:nth-child(2) {
      position: ${(props) => (props.path.indexOf('works') >= 0 ? 'absolute' : 'relative')};
      left: ${(props) => (props.path.indexOf('works') >= 0 ? 50 : props.path.indexOf('community') >= 0 ? 4 : 0)}%;
    }
  }
`
