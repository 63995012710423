import React from 'react'
import Header from './Header'
import Footer from './Footer'
import styled from 'styled-components'
import MainText from '../pages/main/MainText'
import useWindowSize from '../../lib/windowSize'

const MainPageLayout = ({ children }: { children: React.ReactNode }) => {
  const { width } = useWindowSize()

  return (
    <>
      <Header />
      <MainText />
      {width <= 767 ? (
        <>
          <BackLine style={{ left: '8vw' }} />
          <BackLine style={{ left: '50%', right: '50%' }} />
          <BackLine style={{ right: '8vw' }} />
        </>
      ) : (
        <>
          <BackLine style={{ left: '13vw' }} />
          <BackLine style={{ left: '31.25vw' }} />
          <BackLine style={{ left: '50%', right: '50%' }} />
          <BackLine style={{ right: '31.25vw' }} />
          <BackLine style={{ right: '13vw' }} />
        </>
      )}
      {children}
      <Separator />
      <IndicatorWrapper>
        <Indicator name="contacts">CONTACTS</Indicator>
      </IndicatorWrapper>
      <Footer />
    </>
  )
}

export default MainPageLayout

const Indicator = styled.div<{ name: string }>`
  @media screen and (min-width: 768px) {
    position: relative;
    width: 58px;
    height: 16px;
    right: 72px;
    top: 210px;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 0.65vw;
    line-height: 16px;
    text-align: right;
    letter-spacing: 3px;
    text-transform: uppercase;
    color: #979797;
    transform: rotate(-90deg);
  }
  @media screen and (max-width: 767px) {
    display: none;
  }
`

const IndicatorWrapper = styled.div`
  @media screen and (min-width: 768px) {
    margin-left: 13vw;
    margin-right: 13vw;
  }
`

const BackLine = styled.div`
  @media screen and (min-width: 768px) {
    top: 0;
    background: #ededed;
    width: 1px;
    height: 100%;
    z-index: -1;
    position: fixed;
  }

  @media screen and (max-width: 767px) {
    top: 1444px;
    background: #ededed;
    width: 1px;
    height: 5272px;
    z-index: -1;
    position: absolute;
  }
`

const Separator = styled.div`
  @media screen and (min-width: 768px) {
    height: 2px;
    background-color: #ededed;
    width: 100%;
    margin: 0;
    padding: 0;
  }

  @media screen and (max-width: 767px) {
    height: 2px;
    background-color: #ededed;
    right: -13vw;
    width: 140%;
    margin: 0;
    padding: 0;
    position: absolute;
    bottom: 6000px;
  }
`
