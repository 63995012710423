import React, { useEffect } from 'react'
import styled from 'styled-components'
import useWindowSize from '../lib/windowSize'
import vectorIcon2 from '../assets/icon/vectorIcon.png'

const NAVER_MAP_URL =
  'https://map.naver.com/v5/search/%EA%B0%95%EB%82%A8%EB%8C%80%EB%A1%9C%2018%EA%B8%B8%2016-6/address/14141983.72729646,4505074.11664097,%EC%84%9C%EC%9A%B8%ED%8A%B9%EB%B3%84%EC%8B%9C%20%EC%84%9C%EC%B4%88%EA%B5%AC%20%EA%B0%95%EB%82%A8%EB%8C%80%EB%A1%9C18%EA%B8%B8%2016-6,new?c=14141956.1423266,4505067.4261373,19,0,0,0,dh'
const COMPANY_LAT = 37.471924
const COMPANY_LNG = 127.039573

const NaverMap = () => {
  const initMap = () => {
    const naver = window.naver
    if (!naver) {
      return
    }

    const position = new naver.maps.LatLng(COMPANY_LAT, COMPANY_LNG)

    const mapOptions = {
      center: position,
      zoom: 16,
    }

    const map = new naver.maps.Map('map', mapOptions)
    new naver.maps.Marker({
      position,
      map,
      icon: {
        content: `<img src=${vectorIcon2} alt="marker" />`,
      },
    })
  }

  const loadNaverMapSdk = () => {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.async = true
    script.src = `https://openapi.map.naver.com/openapi/v3/maps.js?ncpClientId=${process.env.REACT_APP_NAVER_MAP_CLIENT_KEY}`
    document.body.append(script)
    script.addEventListener('load', initMap)
  }

  useEffect(() => {
    loadNaverMapSdk()
  }, [])
  const { width } = useWindowSize()

  const mapStyle =
    width <= 767
      ? {
          width: '100%',
          height: '347px',
        }
      : { width: '69%', height: '347px' }

  return (
    <MapContainer>
      <div id="map" style={mapStyle} />
      <div className="mapInfo">
        <ul>
          <li id="vectoricon">
            <div id="map" />
          </li>
          <li id="textBox">
            서울특별시 서초구 강남대로18길 16-6
            <br />
            연합빌딩 3층 (구 주소: 서울특별시 서초구 양재동 314 연합빌딩 3층)
          </li>
          <a href={NAVER_MAP_URL} target="_blank" rel="noreferrer">
            <li id="linkApp">네이버 지도로 보기</li>
          </a>
        </ul>
      </div>
    </MapContainer>
  )
}

export default NaverMap

const MapContainer = styled.div`
  @media screen and (min-width: 768px) {
    display: flex;
    flex-direction: row;
    margin-top: 289px;
    margin-bottom: 18px;
    .mapInfo {
      margin-left: 30px;
      display: flex;
    }

    #vectoricon {
      margin-bottom: 12.5px;
    }

    #textBox {
      max-width: 323px;
      font-style: normal;
      font-weight: 600;
      font-size: 1.2vw;
      line-height: 38px;
      text-transform: capitalize;
      color: #414141;
      font-family: 'pretandard-semibold ';
    }

    #linkApp {
      font-style: normal;
      font-weight: 600;
      font-size: 1.2vw;
      line-height: 38px;
      margin-top: 32px;
      text-transform: capitalize;
      text-decoration-line: underline;
      text-transform: capitalize;

      color: #414141;
    }
  }

  @media screen and (max-width: 767px) {
    .mapInfo {
      margin-top: 22.5px;
      margin-bottom: 80px;
    }

    #vectoricon {
      margin-bottom: 12.5px;
    }

    #textBox {
      width: 321px;
      height: 114px;
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 38px;
      text-transform: capitalize;
      color: #414141;
    }

    #linkApp {
      width: 321px;
      height: 38px;
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 38px;
      text-decoration-line: underline;
      text-transform: capitalize;
      color: #414141;
      margin-top: 32px;
    }
  }
`
