import React from 'react'
import styled, { css } from 'styled-components'
import headerIconSvg from '../../../assets/icon/headerIcon.svg'
import { Link, useLocation } from 'react-router-dom'

function HeaderModal({
  open,
  close,
  refData,
  animationOn,
}: {
  open: boolean
  close: () => void
  refData: any
  animationOn: boolean
}) {
  const { pathname } = useLocation()

  return (
    <Container animationOn={animationOn}>
      {open && (
        <div className="modal__background">
          <div className="modal__box" ref={refData}>
            <div className="container">
              <div
                className="icon"
                onClick={() => {
                  close()
                }}
              >
                <img id="headerIcon" src={headerIconSvg} alt="headerIcon" />
              </div>
              <div className="nav_list">
                <ul>
                  <Link to="/works">
                    <li>
                      WORKS
                      <Pointer style={pathname.indexOf('works') >= 0 ? { display: 'block' } : { display: 'none' }} />
                    </li>
                  </Link>
                  <Link to="/about">
                    <li>
                      ABOUT
                      <Pointer style={pathname.indexOf('about') >= 0 ? { display: 'block' } : { display: 'none' }} />
                    </li>
                  </Link>
                  <Link to="/community/blog">
                    <li>
                      COMMUNITY
                      <Pointer
                        style={pathname.indexOf('community') >= 0 ? { display: 'block' } : { display: 'none' }}
                      />
                    </li>
                  </Link>
                  <Link to="/contacts">
                    <li>
                      CONTACTS
                      <Pointer style={pathname.indexOf('contacts') >= 0 ? { display: 'block' } : { display: 'none' }} />
                    </li>
                  </Link>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
    </Container>
  )
}

export default HeaderModal

const Container = styled.div<{ animationOn: boolean }>`
  @media screen and (max-width: 767px) {
    .modal__background {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.5);
      z-index: 9999;
      display: flex;
      justify-content: center;
      height: 4335px;
      ${(props) =>
        props.animationOn === true &&
        css`
          transition: 0.5s all ease-in;
          opacity: 0;
        `}
    }

    .modal__box {
      /* position: relative;
            top: 11113px; */
      position: fixed;
      top: 0;
      left: 0;
      display: flex;
      width: 250px;
      height: 100%;
      background: #ffffff;
      ${(props) =>
        props.animationOn === true &&
        css`
          transition: 0.25s all ease-in;
          transform: translateX(-450px);
        `}
    }

    .container {
      margin-left: 32px;
    }

    .icon {
      margin-top: 60px;
    }

    .nav_list {
      margin-top: 48px;
    }

    .nav_list ul li {
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 3px;
      text-transform: uppercase;
      color: #004099;
      margin-bottom: 32px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
`
const Pointer = styled.div`
  @media screen and (max-width: 767px) {
    width: 4px;
    height: 4px;
    border-radius: 100%;
    background: #004099;
    margin-left: 6px;
    position: relative;
    display: none;
    z-index: 999;
    position: relative;
    bottom: 1.9px;
  }
`
