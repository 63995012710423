import { DefaultTheme } from 'styled-components'

const theme: DefaultTheme = {
  colors: {
    primary: '#004099',
    secondary: '#B8D0F1',
    darkGray: '#979797',
    gray: '#CFCFCF',
    lightGray: '#EDEDED',
    black: '#000000',
    link: '#007FFF',
  },
}

export default theme
