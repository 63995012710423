import styled from 'styled-components'
import { useNavigate, useParams } from 'react-router-dom'
import { customizeDate } from '../../../lib/customizeDate'
import { usePostsId } from '../../../models/usePosts'

const SearchPortfolio = ({ setHashtag }: { setHashtag?: any }) => {
  const params = useParams()
  const { data: portfolio } = usePostsId(Number(params.id))
  const navigate = useNavigate()

  if (!portfolio) {
    return <h1 id="loading">loading...</h1>
  }

  return (
    <Container>
      <div className="title">{portfolio.data.title}</div>
      <div className="info">
        <div className="hashtag">
          {portfolio.data.hashtags.map((item: HashtagType) => (
            <span
              key={item.id}
              onClick={() => {
                navigate(`/works?hashtagId=${item.id}`)
                setHashtag && setHashtag(item.id)
              }}
            >
              {item.name}&nbsp;
            </span>
          ))}
        </div>
        <div className="date">{customizeDate(portfolio.data.createdAt)}</div>
      </div>

      <Separator />
      <div className="content" dangerouslySetInnerHTML={{ __html: portfolio.data.content }} />

      <div
        className="to_list"
        onClick={() => {
          navigate('/works')
        }}
      >
        목록보기
      </div>
    </Container>
  )
}

export default SearchPortfolio

const Container = styled.div`
  @media screen and (min-width: 768px) {
    width: 100%;
    min-height: 800px;

    .title {
      height: auto;
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 40px;
      color: #414141;
      margin-bottom: 14px;
    }

    .info {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .hashtag {
      cursor: pointer;
      height: 24px;
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      color: #004099;
    }

    .date {
      height: 24px;
      font-family: 'Optima';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      color: #414141;
    }

    .content {
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 32px;
      color: #414141;
    }

    .to_list {
      margin-top: 58px;
      height: 40px;
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 40px;
      text-decoration-line: underline;
      color: #414141;
      text-underline-position: under;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    margin-top: 87px;
    margin-bottom: 30px;

    .title {
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 40px;
      color: #414141;
    }

    .info {
      margin-top: 23px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .hashtag {
      cursor: pointer;
      height: 24px;
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      color: #004099;
    }

    .date {
      height: 24px;
      font-family: 'Optima';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      color: #414141;
    }

    .content {
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 32px;
      color: #414141;
      width: 100%;
    }

    .to_list {
      margin-top: 58px;
      text-underline-position: under;
      height: 40px;
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 40px;
      text-decoration-line: underline;
      color: #414141;
    }
  }
`

const Separator = styled.div`
  @media screen and (min-width: 768px) {
    height: 1px;
    background-color: #ededed;
    margin-top: 42px;
    margin-bottom: 24px;
    width: 100%;
  }

  @media screen and (max-width: 767px) {
    height: 1px;
    background-color: #ededed;
    margin-top: 33px;
    margin-bottom: 24px;
    width: 100%;
  }
`
