import React from 'react'
import styled from 'styled-components'
import useWindowSize from '../../../lib/windowSize'

function AboutPart({ item }: { item: AboutContentType }) {
  const { id, title } = item
  const { width } = useWindowSize()

  return (
    <AboutPartContainer order={id}>
      <div className="about-title">{title}</div>
      {id === 1 ? (
        <>
          {width <= 767 ? (
            <div className="content">
              위플래닛은 모바일, 웹으로 구성된 서비
              <br />스 프로토타입 개발에 특화된 팀을 보유 <br />
              하고 있습니다. 기능 정의, 화면 설계 및 <br /> 디자인, 개발과 론칭까지 책임지고 진행 <br />
              하고 있습니다. 사업에 꼭 필요한 분야에 <br /> 집중하실 수 있도록, 위플래닛의 기획,
              <br /> 디자인, 개발의 전문가들이 여러분과
              <br /> 함께 합니다.
            </div>
          ) : (
            <div className="content">
              위플래닛은 모바일, 웹으로 구성된 서비스 프로토타입 개발
              <br />에 특화된 팀을 보유하고 있습니다. 기능 정의, 화면 설계 및<br /> 디자인, 개발과 론칭까지 책임지고
              진행하고 있습니다. 사업
              <br />에 꼭 필요한 분야에 집중하실 수 있도록, 위플래닛의 기획,
              <br /> 디자인, 개발의 전문가들이 여러분과 함께 합니다.
            </div>
          )}
        </>
      ) : id === 2 ? (
        <>
          {width <= 767 ? (
            <div className="content">
              성공적인 프로토타입 개발을 위해 저희는 애자일한 방식으로 프로젝트를 진행합니다. 최소한의 기간 단위로
              계획을
              <br /> 세우고, 기능을 개발하고, 서비스를 개선해나갑니다. 이를 통해 개발 과정을
              <br />
              투명하게 공유하고, 프로젝트 실패
              <br /> 리스크를 줄이며, 고객의 요구 변화에
              <br /> 유연하고신속하게 대응하고자 노력합니다.
            </div>
          ) : (
            <div className="content">
              성공적인 프로토타입 개발을 위해 저희는 애자일한 방식으로 프로젝트를 진행합니다. 최소한의 기간 단위로
              계획을
              <br />
              세우고, 기능을 개발하고, 서비스를 개선해나갑니다. <br />
              이를 통해 개발 과정을 투명하게 공유하고, 프로젝트 실패
              <br /> 리스크를 줄이며, 고객의 요구 변화에 유연하고 신속하게
              <br /> 대응하고자 노력합니다.
            </div>
          )}
        </>
      ) : id === 3 ? (
        <>
          {width <= 767 ? (
            <div className="content">
              프로젝트의 장기적인 관리를 위해 보편적이고, 최신화된 기술을 사용합니다. <br />
              클라우드 인프라(AWS) 위에 Docker, NodeJS(Express, Nest)로 백엔드를
              <br /> 구성하고, JS 프레임워크 / 라이브러리(ReactJS,NextJS, VueJS, NuxtJS, React Native)로 프론트엔드를
              개발
              <br />
              합니다. 모바일 네이티브는 Kotlin(MVVM), Swift로 개발하고 있습니다.
            </div>
          ) : (
            <div className="content">
              프로젝트의 장기적인 관리를 위해 보편적이고, 최신화된 기술을 사용합니다. 클라우드 인프라(AWS) 위에 Docker,
              NodeJS(Express, Nest)로 백엔드를 구성하고,
              <br /> JS 프레임워크 / 라이브러리(ReactJS,NextJS, VueJS, NuxtJS, React Native)로 프론트엔드를
              개발합니다.모바일 네이티브는 Kotlin(MVVM), Swift로 개발하고 있습니다.
            </div>
          )}
        </>
      ) : (
        <>
          {width <= 767 ? (
            <div className="content">
              OS 별 디자인 가이드라인을 준수하고, <br /> 사용자에게 가장 익숙한 방식의 UX를 <br /> 만들어내기 위해
              노력합니다. <br />
              독특함보다는 보편성을 추구하며, <br /> 개발 친화적인 디자인을 선호합니다. <br /> 로고를 통함한 브랜드
              디자인, 웹 및 모바일 화면 디자인, 서비스 운영을 위한 2D 컨텐츠 디자인을 지원합니다. <br /> Sketch / Zeplin
              / Figma를 사용합니다.
            </div>
          ) : (
            <div className="content">
              OS 별 디자인 가이드라인을 준수하고, 사용자에게 가장
              <br /> 익숙한 방식의 UX를 만들어내기 위해 노력합니다.
              <br />
              독특함보다는 보편성을 추구하며, 개발 친화적인 디자인을
              <br /> 선호합니다. 로고를 포함한 브랜드 디자인, 웹 및 모바일 화면 디자인, 서비스 운영을 위한 2D 컨텐츠
              디자인을
              <br /> 지원합니다. Sketch / Zeplin / Figma를 사용합니다.
            </div>
          )}
        </>
      )}
    </AboutPartContainer>
  )
}

export default AboutPart

const AboutPartContainer = styled.div<{ order: number }>`
  @media screen and (min-width: 768px) {
    position: relative;
    display: flex;
    flex-direction: column;
    right: ${(props) => (props.order % 2 === 0 ? 1.3 : 0)}vw;

    .about-title {
      font-style: normal;
      font-weight: 700;
      font-size: 1.7vw;
      line-height: 30px;
      text-transform: capitalize;
      font-feature-settings: 'tnum' on, 'lnum' on, 'cpsp' on;
      color: #000000;
      margin-bottom: 16px;
      margin-top: ${(props) => (props.order === 3 || props.order === 4 ? 64 : 0)}px;
    }

    .content {
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 1.23vw;
      line-height: 2.4vw;
      color: #000000;
      mix-blend-mode: normal;
      opacity: 0.8;
      width: 29vw;
    }
  }

  @media screen and (max-width: 767px) {
    .about-title {
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 28px;
      line-height: 30px;
      text-transform: capitalize;
      font-feature-settings: 'cpsp' on, 'case' on;
      color: #000000;
    }

    .content {
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 38px;
      color: #000000;
      width: 313px;
      mix-blend-mode: normal;
      opacity: 0.8;
      margin-top: 20px;
      margin-bottom: ${(props) => (props.order === 4 ? 81 : 98)}px;
    }
  }
`
