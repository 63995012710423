import { BrowserRouter, useRoutes } from 'react-router-dom'
import Errors from './errors'
import Main from './main'
import Works from './works'
import About from './about'
import Community from './community'
import Contacts from './contacts'
import { ScrollToTop } from '../hooks/useScrollToTop'
import React from 'react'

const Routes = () => {
  return useRoutes([...Errors, Main, Works, About, Contacts, Community])
}

const RoutesWithBrowser = () => (
  <BrowserRouter>
    <ScrollToTop />
    <Routes />
  </BrowserRouter>
)

export default RoutesWithBrowser
