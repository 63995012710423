import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useCallback } from 'react'
import { Container } from '../../NavContainer'

const TAB = [
  { category: '', name: 'ABOUT' },
  { category: 'members', name: 'MEMBERS' },
]

function AboutNav() {
  const params = useParams()
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const handleNavClicked = (category: string): void => {
    if (!category) {
      navigate(`/about`)
    }
    navigate(`/about/${category}`)
  }

  const handleNavTab = useCallback(() => {
    return TAB.map((item, index) => (
      <div
        key={item.name}
        style={
          !params.category && item.name === 'ABOUT'
            ? { color: '#004099' }
            : params.category === item.category
            ? { color: '#004099' }
            : { color: '#CFCFCF' }
        }
        className={`li_${index}`}
        onClick={() => {
          handleNavClicked(item.category)
        }}
      >
        {item.name}
      </div>
    ))
  }, [params])

  return <Container path={pathname}>{handleNavTab()}</Container>
}

export default AboutNav
