import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import { getPostById, getPosts } from './main'
import { postsQueryKey } from '../constants/query-key'

export const usePosts = (params: Params) => {
  return useQuery([...postsQueryKey.posts, params], () => getPosts(params))
}

export const usePostsId = (id: number) => {
  return useQuery(postsQueryKey.postsId(id), () => getPostById(id))
}

export const useInfinitePosts = (params: Params) => {
  return useInfiniteQuery({
    queryKey: [...postsQueryKey.blogInfinite, params],
    queryFn: async ({ pageParam = 0 }) => {
      const { data } = await getPosts({
        ...params,
        start: pageParam,
      })

      return {
        data: data.data,
        nextPage: pageParam,
        total: data.total,
      }
    },
    getNextPageParam: (lastPage) => {
      if (lastPage.nextPage >= lastPage.total) {
        return false
      } else {
        return lastPage.nextPage + 10
      }
    },
  })
}
