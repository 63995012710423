import React from 'react'
import styled from 'styled-components'
import Portfolio from '../main/Portfolio'
import useWindowSize from '../../../lib/windowSize'
import { useInfinitePosts } from '../../../models/usePosts'
import useInfiniteScroll from '../../../hooks/useInfiniteScroll'
import HashtagState from '../../HashtagState'

const Works = () => {
  const { width } = useWindowSize()
  const searchParamsType = new URLSearchParams(location.search).get('type')
  const searchParamsHashtagId = new URLSearchParams(location.search).get('hashtagId')

  const {
    data: portfoliosData,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useInfinitePosts({
    start: 0,
    perPage: 10,
    type: searchParamsType ? (searchParamsType as Type) : undefined,
    hashtagId: searchParamsHashtagId ? Number(searchParamsHashtagId) : undefined,
  })
  const setTarget = useInfiniteScroll({ hasNextPage, isFetchingNextPage, fetchNextPage })

  return (
    <Container>
      <HashtagState<IWork[]> data={portfoliosData} path="/works" />
      <div className={width <= 767 ? 'mobile' : 'desktop'}>
        {portfoliosData?.pages
          .flatMap((page) => page.data)
          .map((item: IWork, index: number) => (
            <div key={index}>
              <Portfolio portfolio={item} key={index} />
            </div>
          ))}
      </div>

      <div ref={setTarget} />
    </Container>
  )
}

export default Works

const Container = styled.div`
  width: 100%;
  @media screen and (min-width: 768px) {
    .desktop {
      display: grid;
      justify-content: space-between;
      grid-template-columns: 48.75% 48.75%;
    }

    .curHashtag {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 29px;
      text-transform: capitalize;
      color: #004099;
      height: 44px;
      width: fit-content;
      border-radius: 40px;
      box-sizing: border-box;
      border: 1px solid #004099;
      margin-bottom: 25px;
    }

    .tagName {
      margin-left: 14px;
    }

    #removeBtn {
      margin-left: 9px;
      margin-right: 13px;
      position: relative;
      top: 2.9px;
    }
  }

  @media screen and (max-width: 767px) {
    margin-top: 68px;
    width: 100%;
    .mobile {
      margin-bottom: 30px;
    }

    .curHashtag {
      position: absolute;
      top: 216px;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 29px;
      text-transform: capitalize;
      color: #004099;
      height: 44px;
      width: fit-content;
      border-radius: 40px;
      box-sizing: border-box;
      border: 1px solid #004099;
    }

    .tagName {
      margin-left: 14px;
    }

    #removeBtn {
      margin-left: 9px;
      margin-right: 13px;
      position: relative;
      top: 2.9px;
    }

    .mobile {
      margin-bottom: 30px;
    }
  }
`
