import { AxiosResponse } from 'axios'
import request from './core'

export const getPosts = (params: Params): Promise<AxiosResponse<IWorks>> => {
  return request(`works`, { method: 'get', params })
}

export const getPostById = (id: number): Promise<AxiosResponse<IWork>> => {
  return request(`works/${id}`)
}

export const getBlogs = (params: Params): Promise<AxiosResponse<IBlogs>> => {
  return request(`blogs`, { method: 'get', params })
}

export const getBlogById = (id: number): Promise<AxiosResponse<IBlog>> => {
  return request(`blogs/${id}`)
}

export const getRecruits = (params: Params): Promise<AxiosResponse<IRecruits>> => {
  return request(`recruits`, { method: 'get', params })
}

export const getRecruitById = (id: number): Promise<AxiosResponse<IRecruit>> => {
  return request(`recruits/${id}`)
}

export const getNotices = (params: Params): Promise<AxiosResponse<INotices>> => {
  return request(`notices`, { method: 'get', params })
}

export const getNoticeById = (id: number): Promise<AxiosResponse<INotice>> => {
  return request(`notices/${id}`)
}

export const getMembers = (params: Params): Promise<AxiosResponse<IMembers>> => {
  return request(`members`, { method: 'get', params })
}

export const postContacts = (name: string, email: string, content: string) => {
  request({
    method: 'post',
    url: '/contacts',
    data: {
      name: name,
      email: email,
      content: content,
    },
  })
}

export default {
  getPosts,
  getBlogs,
  getRecruits,
  getNotices,
  postContacts,
  getPostById,
  getRecruitById,
  getNoticeById,
}
