import AboutNav from '../../components/pages/about/AboutNav'
import styled from 'styled-components'
import AboutSection from '../../components/pages/about/AboutSection'
import { Outlet, useParams } from 'react-router-dom'
import SubPageLayout from '../../components/layouts/SubPageLayout'

const Index = () => {
  const params = useParams()

  return (
    <SubPageLayout>
      <Main>
        <AboutNav />
        {params.category === 'members' ? <Outlet /> : <AboutSection />}
      </Main>
    </SubPageLayout>
  )
}

export default Index

const Main = styled.div`
  @media screen and (min-width: 768px) {
    display: flex;
    flex-direction: row;
    margin-top: 269px;
    margin-left: 13vw;
    margin-right: 13vw;
  }

  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
    margin-top: 142px;
    margin-left: 8vw;
    margin-right: 8vw;
  }
`
