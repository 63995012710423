import styled from 'styled-components'
import { Link } from 'react-router-dom'

function MainIntro() {
  const searchParamsWork = new URLSearchParams(location.search).get('work')

  return (
    <Container>
      <MainIntroContainer>
        <MobileSep>
          <Link to="/">
            <li className={searchParamsWork !== 'weplanet' && searchParamsWork !== 'client' ? 'clickedText' : 'text'}>
              ALL WORKS
            </li>
          </Link>
          <Link to="?work=weplanet">
            <li className={searchParamsWork === 'weplanet' ? 'clickedText' : 'text'}> • WEPLANET</li>
          </Link>
        </MobileSep>

        <MobileSep>
          <Link to="?work=client">
            <li className={searchParamsWork === 'client' ? 'clickedText' : 'text'}> • FOR CLIENTS</li>
          </Link>
          <Link to="/works">
            <li className="more">more</li>
          </Link>
        </MobileSep>
      </MainIntroContainer>
    </Container>
  )
}

export default MainIntro

const Container = styled.div`
  @media screen and (min-width: 768px) {
    width: 100%;
    margin-bottom: 17px;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    margin-top: 72.18px;
    margin-bottom: 10px;
  }
`

const MainIntroContainer = styled.ul`
  .text {
    color: #cfcfcf;
  }

  .clickedText {
    color: #004099;
  }

  @media screen and (min-width: 768px) {
    position: relative;
    display: flex;
    flex-direction: row;
    font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 0.84vw;
    line-height: 19px;
    letter-spacing: 3px;

    li {
      cursor: pointer;
    }

    li:nth-child(2) {
      margin-left: 16px;
      margin-right: 16px;
    }

    .more {
      position: absolute;
      right: 0;
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      color: #cfcfcf;
      letter-spacing: 1px;
    }
  }

  @media screen and (max-width: 767px) {
    position: relative;
    font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 3px;
    text-transform: uppercase;

    display: flex;
    flex-direction: column;

    li:nth-child(1) {
      margin-bottom: 10px;
      margin-right: 10px;
    }

    .more {
      position: absolute;
      right: 0;
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 1px;
      height: 17px;
      color: #cfcfcf;
      text-transform: lowercase;
    }
  }
`

const MobileSep = styled.span`
  @media screen and (min-width: 768px) {
    display: flex;
    flex-direction: row;
  }

  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: row;
  }
`
