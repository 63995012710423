import styled from 'styled-components'

export const Container = styled.div<{ path: string }>`
  @media screen and (min-width: 768px) {
    display: ${(props) => (props.path === '/' ? 'none' : 'flex')};
    flex-direction: column;
    min-width: 200px;
    div {
      cursor: pointer;
      height: 19px;
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 1vw;
      line-height: 19px;
      letter-spacing: 3px;
      text-transform: uppercase;
      margin-bottom: 2vw;
    }
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    display: ${(props) => (props.path === '/' ? 'none' : 'flex')};
    flex-direction: ${(props) => (props.path.indexOf('works') >= 0 ? 'column' : 'row')};
    ${(props) => (props.path.indexOf('works') >= 0 ? '' : 'justify-content: space-between;')}
    div {
      cursor: pointer;
      height: 19px;
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 3px;
      text-transform: uppercase;
      color: #cfcfcf;
      margin-bottom: 10px;
    }

    div:nth-child(2) {
      position: ${(props) => (props.path.indexOf('works') >= 0 ? 'absolute' : 'relative')};
      left: ${(props) => (props.path.indexOf('works') >= 0 ? 50 : props.path.indexOf('community') >= 0 ? 4 : 0)}%;
    }
  }
`
