import Portfolio from './Portfolio'
import { Wrapper } from './PortfoliosWrapper'

interface IProps {
  portfolios?: IWork[]
}

function AllPortfolios({ portfolios }: IProps) {
  return (
    <Wrapper>
      {portfolios?.map((item: IWork) => (
        <Portfolio portfolio={item} key={item.id} />
      ))}
    </Wrapper>
  )
}

export default AllPortfolios
