import styled from 'styled-components'
import { useInfiniteMembers } from '../../../models/useMembers'
import useInfiniteScroll from '../../../hooks/useInfiniteScroll'

const MembersSection = () => {
  const {
    data: membersData,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteMembers({
    start: 0,
    perPage: 10,
  })
  const setTarget = useInfiniteScroll({ hasNextPage, isFetchingNextPage, fetchNextPage })

  return (
    <Container>
      <div className="title">
        hello, we’re <span id="weplanet">weplanet.</span>
      </div>

      <MemberGrid>
        {membersData?.pages
          .flatMap((page) => page.data)
          .map((item: IMember, index: number) => (
            <Card key={index} nameColor={item.nameHexColor}>
              <div className="content">
                <div className="member_img">
                  <img id="img" src={item.profileImage} alt="profile" />
                </div>
                <div className="member_name">{item.name}</div>
                <div className="member_position">{item.position}</div>
              </div>
            </Card>
          ))}
      </MemberGrid>
      <div ref={setTarget} />
    </Container>
  )
}

export default MembersSection

const Container = styled.div`
  @media screen and (min-width: 768px) {
    width: 100%;
    margin-left: 4.25vw;
    margin-bottom: 331px;
    .title {
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 28px;
      line-height: 30px;
      text-transform: capitalize;
      color: #000000;
    }

    .title > #weplanet {
      color: #004099;
    }
  }

  @media screen and (max-width: 767px) {
    margin-top: 85px;
    margin-bottom: 200px;
    .title {
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 28px;
      line-height: 30px;
      text-transform: capitalize;
      color: #000000;
      display: flex;
      justify-content: center;
    }

    .title > #weplanet {
      color: #004099;
    }
  }
`

const MemberGrid = styled.div`
  @media screen and (min-width: 768px) {
    margin-top: 24px;
    width: 100%;
    display: grid;
    grid-template-columns: 23% 23% 23% 23%;
    row-gap: 30px;
    justify-content: space-between;
  }

  @media screen and (max-width: 767px) {
    margin-top: 24px;
    /* width: 100%; */
    display: grid;
    justify-content: space-between;
    grid-template-columns: 48% 48%;
    row-gap: 30px;
  }
`
const Card = styled.div<{ nameColor: string }>`
  @media screen and (min-width: 768px) {
    /* height: 257px; */
    height: 16vw;
    /* width: 12.7vw; */
    /* width: 90%; */
    background: #ffffff;
    /* background: black; */
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    .content {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .member_img {
      display: flex;
      justify-content: center;
      /* margin-bottom: 20px; */
      margin-bottom: 1.2vw;

      img {
        /* width: 119px; */
        width: 7.5vw;
      }
    }

    .member_name {
      color: ${(props) => props.nameColor};
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 600;
      line-height: 30px;
      text-transform: capitalize;
      /* margin-bottom: 10px; */
      margin-bottom: 0.6vw;
      display: flex;
      justify-content: center;
      /* font-size: 22px; */
      font-size: 1.38vw;
    }

    .member_position {
      font-family: 'Optima';
      font-style: normal;
      font-weight: 400;
      /* font-size: 16px; */
      line-height: 30px;
      color: #000000;
      display: flex;
      justify-content: center;
      font-size: 1vw;
    }
  }

  @media screen and (max-width: 767px) {
    height: 257px;
    background: #ffffff;
    /* background: black; */
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    .member_img {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
      margin-top: 24px;

      img {
        width: 119px;
        /* width: 31.5vw; */
      }
    }

    .member_name {
      color: ${(props) => props.nameColor};
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 30px;
      text-transform: capitalize;
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
    }

    .member_position {
      font-family: 'Optima';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 30px;
      color: #000000;
      display: flex;
      justify-content: center;
    }
  }
`
