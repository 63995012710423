import styled from 'styled-components'
import { Link, useNavigate, useParams } from 'react-router-dom'
import useWindowSize from '../../../../lib/windowSize'
import { useNoticeId } from '../../../../models/useNotice'
import { customizeDate } from '../../../../lib/customizeDate'

const SearchNotice = () => {
  const params = useParams()
  const navigate = useNavigate()
  const { data: notice } = useNoticeId(Number(params.id))

  const { width } = useWindowSize()

  if (!notice) {
    return <h1 id="loading">loading...</h1>
  }

  return (
    <Container>
      <div className="top">
        <div className="title">{notice.data.title}</div>
        {width > 767 && <div className="date">{customizeDate(notice.data.createdAt)}</div>}
      </div>
      <div className="info">
        <div className="hashtag">
          {notice.data.hashtags.map((item: HashtagType) => (
            <span
              key={item.id}
              onClick={() => {
                navigate(`/community/notice?hashtagId=${item.id}`)
              }}
            >
              {item.name}&nbsp;
            </span>
          ))}
        </div>
        {width <= 767 && <div className="date">{customizeDate(notice.data.createdAt)}</div>}
      </div>

      <Separator />
      <div className="content" dangerouslySetInnerHTML={{ __html: notice.data.content }} />
      <Link to="/community/notice">
        <div className="to_list">목록보기</div>
      </Link>
    </Container>
  )
}

export default SearchNotice

const Container = styled.div`
  @media screen and (min-width: 768px) {
    width: 100%;
    min-height: 800px;

    .title {
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 26px;
      display: flex;
      align-items: center;
      text-transform: capitalize;
      color: #414141;
    }

    .top {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;
    }

    .hashtag {
      cursor: pointer;
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      color: #004099;
    }

    .date {
      font-family: 'Optima';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      display: flex;
      align-items: center;
      text-align: right;
      color: #414141;
    }

    .content {
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 32px;
      color: #414141;
      width: 100%;
    }

    .to_list {
      margin-top: 80px;
      height: 40px;
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 40px;
      text-decoration-line: underline;
      color: #414141;
      text-underline-position: under;
    }
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    margin-top: 87px;
    margin-bottom: 30px;

    .title {
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 26px;
      display: flex;
      align-items: center;
      text-transform: capitalize;
      color: #414141;
    }

    .info {
      margin-top: 16px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .hashtag {
      cursor: pointer;
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      color: #004099;
    }

    .date {
      font-family: 'Optima';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      display: flex;
      align-items: center;
      text-align: right;
      color: #414141;
    }

    .content {
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 34px;
      text-transform: capitalize;
      color: #414141;
    }

    .to_list {
      margin-top: 80px;
      text-underline-position: under;
      height: 40px;
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 40px;
      text-decoration-line: underline;
      color: #414141;
    }
  }
`

const Separator = styled.div`
  @media screen and (min-width: 768px) {
    height: 1px;
    background-color: #ededed;
    margin-top: 20px;
    margin-bottom: 40px;
    width: 100%;
  }

  @media screen and (max-width: 767px) {
    height: 1px;
    background-color: #ededed;
    margin-top: 32px;
    margin-bottom: 40px;
    width: 100%;
  }
`
