import styled from 'styled-components'
import { customizeDate } from '../../../../lib/customizeDate'
import { Link } from 'react-router-dom'

interface IProps {
  recruit: IRecruit
  num: number
}

function Recruit({ recruit, num }: IProps) {
  const { title, createdAt, id } = recruit

  return (
    <Container order={num}>
      <ul>
        <li>
          <Link to={`/community/recruit/${id}`}>
            <div className="title">{title}</div>
          </Link>

          <div className="postedDate">{customizeDate(createdAt)}</div>
        </li>
      </ul>
    </Container>
  )
}

export default Recruit

const Container = styled.div<{ order: number }>`
  @media screen and (min-width: 768px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: ${(props) => (props.order === 2 ? 0 : 24)}px;

    ul li {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .title {
      height: 40px;
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 40px;
      text-transform: capitalize;
      color: #414141;
      color: ${(props) => (props.order === 2 ? '#ABABAB' : '#414141')};
    }

    .postedDate {
      height: 17px;
      font-family: 'Optima';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      text-align: right;
      color: #a8a8a8;
    }
  }
  @media screen and (max-width: 767px) {
    margin-bottom: ${(props) => (props.order === 2 ? 0 : 24)}px;
    ul li {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      position: relative;
      width: 100%;
    }

    .title {
      font-family: 'Pretendard', 'Apple SD Gothic Neo', Sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 40px;
      text-transform: capitalize;
      width: 237px;
      color: ${(props) => (props.order === 2 ? '#ABABAB' : '#414141')};
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }

    .postedDate {
      height: 17px;
      font-family: 'Optima';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      text-align: right;
      color: #a8a8a8;
      white-space: nowrap;
      position: absolute;
      right: 0;
    }
  }
`
